import * as React from 'react';
import Button from '@mui/joy/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {propertyStatusNames, updatePropertyStatus} from '../../../services/PropertyService';
import { headerText } from '../../components/PropertyCard';
import { Stack } from '@mui/material';
import { Typography } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import HideSourceIcon from '@mui/icons-material/HideSource';
import ReceiptIcon from '@mui/icons-material/Receipt';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StatusUpdateDialog = ({property, setOpen, onEdit, onReloadData, onSuccess, onError}) => {

  const onUpdateStatus = (status) => {
    updatePropertyStatus(
      property?.id,
      status,
      (response) => {
          onSuccess(response)
          handleClose();
          onReloadData();
      },
      (error) => {
          onError(error)
      }
    )
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
      <Dialog
        open={property != null}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{headerText(property)}</DialogTitle>
        <DialogContent>
        <Typography level="h6" fontSize="md" sx={{ mb: 0.5 }}>
            {property?.address}
        </Typography>     

          <Typography level="subtitile" fontSize="md" sx={{ mt: 4, mb: 2,  flexGrow: 1, textAlign: "center" }} >
            Update property status.
          </Typography>

          <Stack 
            direction='row' 
            spacing={2} 
            sx={{mx:4, mb:1}}
            justifyContent="center"
            alignItems="center">

            <Button variant='outlined' color="info" startDecorator={<EditIcon />} sx={{width: 140 }} onClick={() => onEdit(property)}>Edit</Button>
            <Button variant='outlined' color="danger" startDecorator={<HideSourceIcon />} sx={{width: 140 }} onClick={() => onUpdateStatus(propertyStatusNames.notAvailable)}>Un Publish</Button>
            <Button variant='outlined' color="success" startDecorator={<ReceiptIcon />} sx={{width: 140 }} onClick={() => onUpdateStatus(propertyStatusNames.sold)}>Sold</Button>

          </Stack>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
  );
}

export default StatusUpdateDialog;