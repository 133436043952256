
import * as React from 'react';
import Grid from '@mui/material/Box';
import PropertyCard from '../../components/PropertyCard';

const GetContent = ({properties, onSuccess, onError, setShowLogin}) => {
    console.log('GetContent', properties)
    const data = [];
    properties.forEach((item) => 
        data.push(
            <Grid item xs={12} md={6} lg={4}>
                <PropertyCard property={item} onSuccess={onSuccess} onError={onError} setShowLogin={setShowLogin}/>                    
            </Grid>
        )
    )
    return data
}

const BookmarkTab = ({ bookmarks, setShowLogin, onSuccess, onError}) => {

    return (
        <GetContent properties={bookmarks} onSuccess={onSuccess} onError={onError} setShowLogin={setShowLogin}/>
    )

}

export default BookmarkTab;