import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { apiUrl } from '../../../utils/api';
import DefImage from '../../../assets/town.png'
import { saveBookmarks, deleteBookmarks } from '../../services/BookmarkService';
import { dateToShowDateFormat, formatNumber } from '../../../utils/ext';
import {isAuthenticated} from '../../../utils/session'
import { Link } from 'react-router-dom';




export const headerText = (property) => {
    return `${property?.type_name} for ${property?.sale_type}`
}

const PropertyCard = ({property, setShowLogin, onSuccess, onError}) => {

  const isAuthenticatedUser = isAuthenticated();

  const [bookmark, setBookmark] = React.useState(property.bookmark)

  const onAddBookmark = () => {

    console.log('onAddBookmark', isAuthenticatedUser);
    if(!isAuthenticatedUser) {
      setShowLogin(true)
      return
    }

    setBookmark(true);
    console.log('onAddBookmark', bookmark);
    saveBookmarks(property.id, (response) => {
      onSuccess(response);
    }, (error) => {
      setBookmark(false);
      onError(error)
    })
  }

  const onRemoveBookmark = () => {
    console.log('onAddBookmark', isAuthenticatedUser.current);
    if(!isAuthenticatedUser) {
      setShowLogin(true)
      return
    }
    setBookmark(false);
    console.log('onRemoveBookmark', bookmark);
    deleteBookmarks(property.id, (response) => {
      onSuccess(response);
    }, (error) => {
      setBookmark(true);
      onError(error)
    })
  }

  const GetIcon = () => {
    if(bookmark) {
        return <Button variant='text' size='small' onClick={onRemoveBookmark}><BookmarkIcon color="primary"  /></Button>
    }
    return <Button variant='text' size='small' onClick={onAddBookmark}><BookmarkAdd /></Button>
  }

  const PhotoView = () => {
      if(property.photos) {
          return (
            <img
              src={`${apiUrl.property}${property.photos[0].media}`}
              srcSet={`${property.photos[0]}`}
              loading="lazy"
              alt=""
              width={300}
          />
        )
      }
     return (
        <img
          src={DefImage}
          srcSet={DefImage}
          loading="lazy"
          alt=""
          width={300}
      />
    )      
  }

  return (
    <Card variant="outlined" sx={{ minWidth: 300,   m:1 }}>
      <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
        {headerText(property)}
      </Typography>
      <Typography level="h6" fontSize="md" sx={{ mb: 0.5 }}>
        in {property.landmark}
      </Typography>      
      <IconButton
        aria-label="bookmark Bahamas Islands"
        variant="plain"
        color="neutral"
        size="sm"
        sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
      >
        <GetIcon />
      </IconButton>
      <AspectRatio  
        variant="plain"
        ratio="4/3"
        objectFit="contain"
        minHeight={120} 
        maxHeight={200}
        sx={{
          flexBasis: `200px`,
          borderRadius: 'sm',
          overflow: 'auto',
        }}
        >
        <PhotoView property={property} />
      </AspectRatio>
      <Typography level="body2">{dateToShowDateFormat(property.created)}</Typography>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Typography fontSize="lg" fontWeight="lg">
          {property.currency} {formatNumber(property.cost)} 
          </Typography>
        </div>
        <Button
          variant="solid"
          size="sm"
          color="primary"
          aria-label="Explore Bahamas Islands"
          sx={{ ml: 'auto', fontWeight: 600 }}
          component={Link}
          to={`/details?property=${property?.id}`}
        >
          Explore
        </Button>
      </Box>
    </Card>
  );
}

export default PropertyCard