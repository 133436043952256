import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import EditNote from '@mui/icons-material/EditNote';
import Avatar from '@mui/material/Avatar';
import {getUser, getProfilePhoto} from '../../../../utils/session'
import { Grid, Stack } from '@mui/material';
import { red } from '@mui/material/colors';

import ErrorIcon from '@mui/icons-material/Error';

const AccountInfoCard = ({showUpdate, showChangePassword, verifyEmail, verifyPhone}) => {

  const user = React.useRef(getUser())
  const profilePhoto = React.useRef(getProfilePhoto())

  return (
    <Card variant="outlined" sx={{ m:1, }}>
       <Typography level="h1" fontSize="md">
          Account Details
        </Typography>

      <Grid container spacing={2} justify="space-between" alignItems="stretch">
        <Grid item sm={12} lg={2}>
          <Avatar
                alt={`${user.current.firstName} ${user.current.lastName}`}
                src={profilePhoto.current}
                sx={{ width: 140, height: 140, m: 2 }}
          />  
        </Grid>
        <Grid item sm={12} lg={8}>
          <Box>
            <Typography level="body1" fontSize="sm">
              Name
            </Typography>
            <Typography level="h2" fontSize="md" sx={{ mb: 2}}>
              {user.current.firstName} {user.current.lastName}
            </Typography>  
          </Box> 
          <Box>
            <Typography level="body1" fontSize="sm">
              Email
            </Typography>
            <Typography level="h2" fontSize="md" sx={{ mb: 2 }}>
              {user.current.email}
            </Typography>  
          </Box>   
          <Box>
            <Typography level="body1" fontSize="sm">
              Phone Number
            </Typography>
            <Typography level="h2" fontSize="md" sx={{ mb: 2 }}>
              {user.current.mobile}
            </Typography>  
          </Box>   
          <Stack direction='row' spacing={2}>
            <Button variant="outlined" color='warning' size="small" onClick={verifyEmail}>
              <ErrorIcon sx={{ color: red[500] }} /> Verify Email
            </Button>
            <Button variant="outlined" color='warning' size="small" onClick={verifyPhone}>
              <ErrorIcon sx={{ color: red[500] }} /> Verify Phone
            </Button>
          </Stack>
        </Grid>
        <Grid item sm={12} lg={2}>
          <Stack spacing={2}>
            <Button variant='outlined' onClick={() => showUpdate(true)}>Edit Account Details</Button>
            <Button variant='outlined' onClick={() => showChangePassword(true)}>Change Password</Button>
          </Stack>
        </Grid>
      </Grid>
               
      {/* <IconButton
        aria-label="bookmark Bahamas Islands"
        variant="plain"
        color="neutral"
        size="sm"
        sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
        onClick={() => showUpdate(true)}
      >
        <EditNote />
      </IconButton> */}
    </Card>
  );
}

export default AccountInfoCard;