
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import AccountInfoCard from '../components/AccountInfoCard';
import UpdateInfoModal from '../components/UpdateInfoModal';
import ChangePasswordModal from '../components/ChangePasswordModal';
import VerifyPhoneModal from '../components/VerifyPhoneModal';
import VerifyEmailModal from '../components/VerifyEmailModal';

import { Paper, Stack } from '@mui/material';


const AccountTab = ({ onSuccess, onError}) => {

    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [openChangePassword, setOpenChangePassword] = React.useState(false);
    const [openVerifyPhone, setOpenVerifyPhone] = React.useState(false);
    const [openVerifyEmail, setOpenVerifyEmail] = React.useState(false);

    const verifyEmail = () => {
        setOpenVerifyEmail(true)
    }

    const verifyPhone = () => {
        setOpenVerifyPhone(true)
    }

    return (
        <Box>
            <Stack>
                <AccountInfoCard showUpdate={setOpenUpdate} showChangePassword={setOpenChangePassword} verifyEmail={verifyEmail} verifyPhone={verifyPhone} />
            </Stack>
            <UpdateInfoModal open={openUpdate} setOpen={setOpenUpdate} onSuccess={onSuccess} onError={onError}/>
            <ChangePasswordModal open={openChangePassword} setOpen={setOpenChangePassword} onSuccess={onSuccess} onError={onError}/>
            <VerifyPhoneModal open={openVerifyPhone} setOpen={setOpenVerifyPhone} onSuccess={onSuccess} onError={onError}/>
            <VerifyEmailModal open={openVerifyEmail} setOpen={setOpenVerifyEmail} onSuccess={onSuccess} onError={onError}/>
        </Box>

    )

}

export default AccountTab;