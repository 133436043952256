import { apiUrl, apiPostSecure, apiCallSecureGet, apiCallSecureDelete, apiCallGetIgnoreLogin, apiPostIgnoreLogin } from "../../utils/api";


export const propertyStatusNames = {
    available: 'A',
    notAvailable: 'N',
    sold: 'S',
    drafts: 'D',
    inReview: 'R',
}

export const propertyTypeNames = {
    any: 'A',
    residental: 'R',
    commercial: 'C',
}

export default async function getProperties(type, onResponse, onError) {

    apiCallSecureGet(
        `${apiUrl.propertyList}?status=${type}`,
        (response) => {
            onResponse(response.list)
        },
        (error) => {
            onError(error)
        } 
    )

}

export async function getPublishedProperties(onResponse, onError) {
    await getProperties('A', onResponse, onError)
}

export async function getDraftProperties(onResponse, onError) {
    await getProperties('D', onResponse, onError)
}

export async function getInReviewProperties(onResponse, onError) {
    await getProperties('R', onResponse, onError)
}

export async function getClosedProperties(onResponse, onError) {
    await getProperties('S', onResponse, onError)
}

export async function getNotAvailableProperties(onResponse, onError) {
    await getProperties('N', onResponse, onError)
}

export async function deleteProperty(id, onResponse, onError) {
    await apiCallSecureDelete(
        apiUrl.propertyList,
        id,
        (response) => {
            onResponse(response.msg)
        },
        (error) => {
            onError(error)
        } 
    )
}

export async function getRecentProperties(onResponse, onError) {
    apiCallGetIgnoreLogin(
        apiUrl.recentproperties,
        (response) => {
            onResponse(response.data)
        },
        (error) => {
            onError(error)
        } 
    )
}

export async function getHomeProperties(onResponse, onError) {
    apiCallGetIgnoreLogin(
        apiUrl.homeProperties,
        (response) => {
            onResponse(response.list)
        },
        (error) => {
            onError(error)
        } 
    )
}


export async function updatePropertyStatus(id, status, onResponse, onError) {

    const body = {
        id: id,
        status: status
    }

    apiPostSecure(
        apiUrl.propertyStatus,
        body,
        (response) => {
            onResponse(response.msg)
        },
        (error) => {
            onError(error)
        } 
    )
}

export async function searchProperty(query, onResponse, onError) {

    apiPostIgnoreLogin(
        apiUrl.searchProperties,
        query,
        (response) => {
            onResponse(response.list)
        },
        (error) => {
            onError(error)
        } 
    )
}

export async function getPropertyDetails(id, onResponse, onError) {
    const url = `${apiUrl.propertyInfo}?property=${id}`
    apiCallGetIgnoreLogin(
        url,
        (response) => {
            onResponse(response.data)
        },
        (error) => {
            onError(error)
        } 
    )
}