
import React from 'react';
import { Box, Grid, Typography, Card, CardActions, CardContent, Button, Stack, Alert, Snackbar, Chip, Avatar } from '@mui/material';
import { saveBookmarks, deleteBookmarks } from '../services/BookmarkService';
import { getHomeProperties, getPropertyDetails } from '../services/PropertyService';
import LoginRequiredModal from '../property/components/LoginRequiredModal';
import isAuthenticated from '../../utils/session';
import ImageSlider from './components/ImageSlider';
import { dateToShowDateFormat, dateToShowFormat2, formatNumber, useQueryParams } from '../../utils/ext'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { lightBlue } from '@mui/material/colors';
import { headerText } from '../main/components/PropertyCard';
import { BookmarkAdd, Bookmark } from '@mui/icons-material';
import HomeSlider from '../main/components/HomeSlider';
import ContactModal from './components/ContactModal';
import { accountId } from '../../utils/session'

const DEF_DELAY = 5000;
const SNAKBAR_POSITION = {
    vertical: 'top',
    horizontal: 'right',
}

const getPropertyType = (type) => {
    if (type == 'R') {
        return 'Residental'
    }
    return 'Commercial'
}

const getIcon = ({ include }) => {
    if (include) {
        return (
            <Button startIcon={<CheckCircleIcon color='success' />} variant='outlined' color='success' sx={{ my: 2 }}>Yes</Button>
        )
    }

    return (
        <Button startIcon={<CancelIcon color='error' />} variant='outlined' color='error' sx={{ my: 2 }}>No</Button>
    )
}

const GetNameNumberBox = ({ name, value }) => {
    return (
        <Box sx={{ p: 2, m: 1, border: 1, borderRadius: '16px', borderColor: '#aaa', width: 160, height: 80 }}>
            <Typography variant='subtitle'>
                {name}
            </Typography>
            <Typography variant='h5'>
                <Avatar sx={{ bgcolor: lightBlue[500], width: 30, height: 30, mt: 1 }}>{value}</Avatar>
            </Typography>
        </Box>
    )
}

const GetNameValueBox = ({ name, value }) => {
    return (
        <Box sx={{ p: 2, m: 1, border: 1, borderRadius: '16px', borderColor: '#aaa', width: 160, height: 80 }}>
            <Typography variant='subtitle'>
                {name}
            </Typography>
            <Typography variant='h5'>
                {value}
            </Typography>
        </Box>
    )
}

const GetNameYesNoBox = ({ name, value }) => {
    if (value) {
        return (
            <Box sx={{ p: 2, m: 1, border: 1, borderRadius: '16px', borderColor: '#aaa', width: 160, height: 80 }}>
                <Typography variant='subtitle'>
                    {name}
                </Typography>
                <Button startIcon={<CheckCircleIcon color='success' />} variant='outlined' color='success' sx={{ my: 2 }}>Yes</Button>
            </Box>
        )
    }
    return (
        <Box sx={{ p: 2, m: 1, border: 1, borderRadius: '16px', borderColor: '#aaa', width: 160, height: 80 }}>
            <Typography variant='subtitle'>
                {name}
            </Typography>
            <Button startIcon={<CancelIcon color='error' />} variant='outlined' color='error' sx={{ my: 2 }}>No</Button>
        </Box>
    )
}

const GetNegotialble = ({ property }) => {
    if (property.negotiable) {
        return (<Chip size="small" label="Negotiable" color="success" variant="outlined" />)
    }
    return (<Chip size="small" label="Non Negotiable" color="primary" variant="outlined" />)
}


const GetBookmarkIcon = ({ property, onRemoveBookmark, onAddBookmark }) => {
    if (property.bookmark) {
        return <Button variant='contained' size='small' onClick={onRemoveBookmark} sx={{ width: '100%' }}><Bookmark />Bookmark Added</Button>
    }
    return <Button variant='outlined' size='small' onClick={onAddBookmark} sx={{ width: '100%', }}><BookmarkAdd /> Add to Bookmarks</Button>
}

const GetDescription = ({property}) => {
    if(property == null) {
        return null;
    }

    if(property.description) {
        return (
            <Stack direction='column'>
                <Grid container justify="center">
                    <Typography variant='subtitle' color='#888' sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}>
                        Description
                    </Typography>
                </Grid>
                <Box sx={{ mt: 2 }}>
                    <Typography variant='subtitle'>
                        {property.description}
                    </Typography>
                </Box>
            </Stack>
        )
    }

    return null;
}

const GetCostDetails = ({ property }) => {

    if (property == null) {
        return null
    }

    console.log('property', property);

    if (property.sale_type == 'Rent') {
        return (
            <Stack direction='column' >
                <Grid container justify="center">
                    <Typography variant='subtitle' color='#888' sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}>
                        Cost details
                    </Typography>
                </Grid>
                <Box sx={{ mt: 2 }}>
                    <Typography variant='subtitle'>
                        Montly Rent
                    </Typography>
                    <Typography variant='h5'>
                        {property.currency} ${formatNumber(property.cost)} <GetNegotialble property={property} />
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant='subtitle'>
                        Montly Maintenance
                    </Typography>
                    <Typography variant='h5'>
                        {property.currency} {formatNumber(property.maintenance)}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant='subtitle'>
                        Available Date
                    </Typography>
                    <Typography variant='h5'>
                        {dateToShowFormat2(property.available_date)}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant='subtitle'>
                        Include Eletricity Bill
                    </Typography>
                    <Typography variant='h6'>
                        {getIcon(property.electricity)}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant='subtitle'>
                        Include Water charge
                    </Typography>
                    <Typography variant='h6'>
                        {getIcon(property.water)}
                    </Typography>
                </Box>
            </Stack>
        )
    }

    return (
        <Stack direction='column'>
            <Grid container justify="center">
                <Typography variant='subtitle' color='#888' sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}>
                    Cost details
                </Typography>
            </Grid>
            <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle'>
                    Selling Cost
                </Typography>
                <Typography variant='h5'>
                    {property.currency} {formatNumber(property.cost)}
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle'>
                    Available Date
                </Typography>
                <Typography variant='h5'>
                    {dateToShowFormat2(property.available_date)}
                </Typography>
            </Box>
        </Stack>
    )
}

const GetPropertyDetailsCard = ({ property }) => {

    if (property == null) {
        return null
    }

    return (
        <Stack direction='column'>
            <Grid container justify="center">
                <Typography variant='subtitle' color='#888' sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}>
                    Property Details
                </Typography>
            </Grid>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', mt: 2 }}>
                <GetNameValueBox name='Residental/Commercial' value={getPropertyType(property.type)} />
                <GetNameValueBox name='Type' value={property.type_name} />
                <GetNameValueBox name='Age of property' value={`${property.property_age} Years`} />
                <GetNameValueBox name='Furnishing' value={property.furnishing} />
                <GetNameNumberBox name='No of rooms' value={property.bedrooms} />
                <GetNameNumberBox name='No of Bathrooms' value={property.bathrooms} />
                <GetNameNumberBox name='No of Balconies' value={property.balconies} />
                <GetNameNumberBox name='Floor No' value={property.floor_no} />
                <GetNameNumberBox name='Total floors' value={property.total_floors} />
                <GetNameYesNoBox name='Rent Family' value={property.rent_family} />
                <GetNameYesNoBox name='Rent Singple Woman' value={property.rent_women} />
                <GetNameYesNoBox name='Rent Single Men' value={property.rent_men} />
            </Box>
        </Stack>
    )
}

const GetLocationDetailsCard = ({ property }) => {

    if (property == null) {
        return null
    }

    return (
        <Stack direction='column'>
            <Grid container justify="center">
                <Typography variant='subtitle' color='#888' sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}>
                    Location details
                </Typography>
            </Grid>
            <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle'>
                    Address
                </Typography>
                <Typography variant='h6'>
                    {property.address}
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle'>
                    landmark
                </Typography>
                <Typography variant='h6'>
                    {property.landmark}
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle'>
                    City
                </Typography>
                <Typography variant='h6'>
                    {property.city}
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle'>
                    Country
                </Typography>
                <Typography variant='h6'>
                    {property.country}
                </Typography>
            </Box>
        </Stack>
    )
}


const GetContactDetails = ({ property }) => {

    if (property == null) {
        return null
    }

    return (
        <Stack direction='column'>
            <Grid container justify="center">
                <Typography variant='subtitle' color='#888' sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}>
                    Contact details
                </Typography>
            </Grid>
            <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle'>
                    Name
                </Typography>
                <Typography variant='h6'>
                    {property?.account?.first_name} {property?.account?.last_name}
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle'>
                    Mobile/Phone
                </Typography>
                <Typography variant='h6'>
                    {property?.account?.mobile}
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography variant='subtitle'>
                    Email
                </Typography>
                <Typography variant='h6'>
                    {property?.account?.email}
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>

            </Box>
        </Stack>
    )
}

function DetailsPage() {

    const [loadData, setLoadData] = React.useState(true);
    const [showContactModal, setShowContactModal] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [successMessage, setSuccessMessage] = React.useState(null);
    const [propertyDetails, setPropertyDetails] = React.useState(null);
    const [showLogin, setShowLogin] = React.useState(false);
    const [renectProperties, setRecentProperties] = React.useState([]);
    const loginUserId = React.useRef(accountId());

    const isAuthenticatedUser = isAuthenticated();

    const { property } = useQueryParams();

    const onSuccess = (msg) => {
        setSuccessMessage(msg);
    }

    const onError = (msg) => {
        setErrorMessage(msg);
    }

    const onAddBookmark = () => {

        console.log('onAddBookmark', isAuthenticatedUser);
        if (!isAuthenticatedUser) {
            setShowLogin(true)
            return
        }

        // propertyDetails?.bookmark = true;
        setPropertyDetails({
            ...propertyDetails,
            bookmark: true,
        });
        saveBookmarks(propertyDetails.id, (response) => {
            onSuccess(response);
        }, (error) => {
            // propertyDetails?.bookmark = false;
            onError(error)
            setPropertyDetails({
                ...propertyDetails,
                bookmark: false,
            });
        })
    }

    const onRemoveBookmark = () => {
        console.log('onAddBookmark', isAuthenticatedUser.current);
        if (!isAuthenticatedUser) {
            setShowLogin(true)
            return
        }
        // propertyDetails?.bookmark = false;
        setPropertyDetails({
            ...propertyDetails,
            bookmark: false,
        });
        deleteBookmarks(propertyDetails.id, (response) => {
            // propertyDetails?.bookmark = true;
        }, (error) => {
            // propertyDetails?.bookmark = false;
            onError(error)
            setPropertyDetails({
                ...propertyDetails,
                bookmark: false,
            });
        })
    }

    React.useEffect(() => {

        if (!loadData) {
            return
        }
        setLoadData(false);

        getHomeProperties(
            (data) => {
                setRecentProperties(data);
            },
            (error) => {
                setErrorMessage(error);
            }
        )

        getPropertyDetails(
            property,
            (data) => {
                setPropertyDetails(data);
            },
            (error) => {
                setErrorMessage(error);
            }
        )
    }, [loadData])

    const checkLoginAndContact = () => {
        if (!isAuthenticatedUser) {
            setShowLogin(true)
            return
        }

        setShowContactModal(true);

    }

    const onCloseError = () => setErrorMessage(null);
    const onCloseSuccess = () => setSuccessMessage(null);

    const GetOwnerDetails = () => {
        if(propertyDetails == null) {
            return null;
        }
        console.log('propertyDetails', loginUserId.current, propertyDetails.account.accountId);

        if(loginUserId.current == propertyDetails.account.accountId) {
            return(
                <Card sx={{ m: 2, p: 3, borderRadius: '16px', flexGrow: 1, }}>
                    <GetContactDetails property={propertyDetails} />
                    <GetBookmarkIcon property={propertyDetails} onAddBookmark={onAddBookmark} onRemoveBookmark={onRemoveBookmark} />
                </Card>
            );
        }
        
        if (isAuthenticatedUser) {
            return (
                    <Card sx={{ m: 2, p: 3, borderRadius: '16px', flexGrow: 1, }}>
                        <GetContactDetails property={propertyDetails} />
                        <Button size='small' variant='contained' onClick={() => checkLoginAndContact()} fullWidth sx={{ mb: 1 }} >Contact Owner</Button>
                        <GetBookmarkIcon property={propertyDetails} onAddBookmark={onAddBookmark} onRemoveBookmark={onRemoveBookmark} />
                    </Card>
            )
        }
        return (
            <Card sx={{ m: 2, p: 3, borderRadius: '16px', flexGrow: 1, }}>
                <Grid container justify="center">
                    <Typography variant='subtitle' sx={{ flexGrow: 1, textAlign: "center" }}>
                        Interested? Get contact details of owner ?
                    </Typography>
                </Grid>
                <Button size='small' variant='contained' sx={{ width: '100%', m: 2 }} onClick={() => setShowLogin(true)}>Contact Owner</Button>
            </Card>
        )
    }

    return (
        <Box>
            <Box sx={{ px: 4 }}>
                {/* <Stack sx={{m:2}} direction="row" justifyContent="space-between">
                <Typography variant='h4'>{headerText(propertyDetails)}</Typography>
                <Stack direction='row-reverse'>
                    <GetBookmarkIcon property={propertyDetails} onAddBookmark={onAddBookmark} onRemoveBookmark={onRemoveBookmark}/>            
                </Stack>
            </Stack> */}
                <Stack direction='row'>
                    <Grid container>
                        <Grid item md={12} lg={12}>
                            <Box>
                                <Card sx={{ mt: 4, mx: 2, p: 3, borderRadius: '16px' }}>
                                    <ImageSlider property={propertyDetails} />
                                </Card>
                            </Box>
                        </Grid>
                        <Grid item md={12} lg={8}>
                            <Card sx={{ m: 2, p: 3, borderRadius: '16px', flexGrow: 1, height: 550 }}>
                                <GetPropertyDetailsCard property={propertyDetails} />
                            </Card>
                        </Grid>
                        <Grid item md={12} lg={4}>
                            <Card sx={{ m: 2, p: 3, borderRadius: '16px', flexGrow: 1, height: 550 }}>
                                <GetLocationDetailsCard property={propertyDetails} />
                            </Card>
                        </Grid>

                    </Grid>
                    <Box>
                        <Box>
                            <Card sx={{ mt: 4, mx: 2, p: 3, borderRadius: '16px' }}>
                                <GetCostDetails property={propertyDetails} />
                            </Card>
                        </Box>
                        <Box>
                            <Card sx={{ mt: 2, mx: 2, p: 3, borderRadius: '16px' }}>
                                <GetDescription property={propertyDetails}/>
                            </Card>
                        </Box>
                        <Box>
                            <GetOwnerDetails />
                        </Box>
                    </Box>
                </Stack>
            </Box>
            <Typography variant='h6' sx={{ marginTop: '20px' }}>Similar Properties</Typography>
            <HomeSlider properties={renectProperties} setShowLogin={setShowLogin} onSuccess={onSuccess} onError={onError} />
            <Snackbar anchorOrigin={SNAKBAR_POSITION} open={successMessage != null} autoHideDuration={DEF_DELAY} onClose={onCloseSuccess}>
                <Alert onClose={onCloseSuccess} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={SNAKBAR_POSITION} open={errorMessage != null} autoHideDuration={DEF_DELAY} onClose={onCloseError}>
                <Alert onClose={onCloseError} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <LoginRequiredModal open={showLogin} setOpen={setShowLogin} />
            <ContactModal open={showContactModal} setOpen={setShowContactModal} onSuccess={onSuccess} onError={onError} propertyDetails={propertyDetails} />
        </Box>
    )
}

export default DetailsPage;