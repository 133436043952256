import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useForm, Controller } from "react-hook-form";


import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, Box } from '@mui/system';
import Modal from '@mui/base/Modal';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

import { apiUrl, apiPostSecure } from "../../../utils/api";
import { TextareaAutosize } from '@mui/material';
import { CancelRounded, Chat } from '@mui/icons-material';
import { ModalClose } from '@mui/joy';

const ContactModal = ({ open, setOpen, propertyDetails, onSuccess, onError }) => {

  const [isLoading, setLoading] = React.useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [error, setError] = React.useState('')

  React.useEffect(() => {
    if (open) {
      setValue('role', 'U');
    }
  }, [open])

  async function sendMessage(data) {
    console.log('sendMessage', data);
    console.log('propertyDetails', propertyDetails);
    setLoading(true);

    const requestData = {
      message: data.message,
      receiver: propertyDetails.account.accountId
    }

    apiPostSecure(
      apiUrl.chatMessage,
      requestData,
      (response) => {
        setLoading(false);
        setValue('message', null);
        setOpen(false);
        onSuccess('Message sent. Owner will contact you soon! You can check responses in Profile > Chats.');
      },
      (errorMsg) => {
        setLoading(false);
        console.log('error', errorMsg)
        setError(errorMsg);
      }
    );
  }

  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      slots={{ backdrop: StyledBackdrop }}
    >
      <Box sx={style}>
        <Box
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <Chat />
          </Avatar>
          <Typography component="h1" variant="h5">
            Contact Owner / Seller
          </Typography>
          <ModalClose
            variant="outlined"
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
              borderRadius: '50%',
              bgcolor: 'background.body',
            }}
          />
        </Box>
        <form id="contact-form" onSubmit={handleSubmit(sendMessage)}>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="ta-contact"
                  label="Entery your message"
                  name="message"
                  multiline
                  rows={4}
                  autoComplete="off"
                  {...register("message", { required: "Enter message" })}
                />
              </Grid>
            </Grid>
            <LoadingButton fullWidth size="large" form="contact-form" type="submit" variant="contained" loading={isLoading} sx={{ mt: 3, mb: 2 }}>
              Send message
            </LoadingButton>
            <Button variant='text' onClick={() => setOpen(false)} fullWidth>Cancel</Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography variant='body' color='error'>{error}</Typography>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </StyledModal>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};


const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: 400,
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});

export default ContactModal;