
import * as React from 'react';
import Grid from '@mui/material/Box';
import MyPropertyCard from '../components/MyPropertyCard';
import { propertyStatusNames } from '../../../services/PropertyService';

  const GetContent = ({option, porperties, onEdit, onUpdateStatus, onUpdatePublish}) => {
    const data = [];
    porperties.forEach((item) => 
        data.push(
            <Grid item xs={12} md={6} lg={4}>
                <MyPropertyCard property={item} onUpdateStatus={onUpdateStatus} onUpdatePublish={onUpdatePublish} onEdit={onEdit} option={option}/>                    
            </Grid>
        )
    )
    return data
}


const PropertiesData = ({ type, publishedProperties, inReviewProperties, draftProperties, closedProperties, notAvailableProperties, onEdit, onUpdateStatus, onUpdatePublish }) => {


  console.log('type', type);

  switch(type) {
    case propertyStatusNames.available:
      return (
        <GetContent option={type} porperties={publishedProperties} onEdit={onEdit} onUpdateStatus={onUpdateStatus} onUpdatePublish={onUpdatePublish}/>
      )
    case propertyStatusNames.inReview:
      return (
        <GetContent option={type} porperties={inReviewProperties} onEdit={onEdit} onUpdateStatus={onUpdateStatus} onUpdatePublish={onUpdatePublish}/>
      )
    case propertyStatusNames.drafts:
      return (
        <GetContent option={type} porperties={draftProperties} onEdit={onEdit} onUpdateStatus={onUpdateStatus} onUpdatePublish={onUpdatePublish}/>
      )
    case propertyStatusNames.sold:
      return (
        <GetContent option={type} porperties={closedProperties} onEdit={onEdit} onUpdateStatus={onUpdateStatus} onUpdatePublish={onUpdatePublish}/>
      )   
    case propertyStatusNames.notAvailable:
      return (
        <GetContent option={type} porperties={notAvailableProperties} onEdit={onEdit} onUpdateStatus={onUpdateStatus} onUpdatePublish={onUpdatePublish}/>
      )
    default:
      return null;
    }

}

export default PropertiesData;