import { Avatar } from "@mui/material"
import { apiUrl } from "../../../../utils/api"
import { getNameInitails } from "../../../../utils/ext"



const AccountPhoto = ({account}) => {

    if(account.photo) {
        return(
            <Avatar alt={`${account.first_name} ${account.last_name}`} src={`${apiUrl.account}${account.photo}`} />
        )
    }

    return(
        <Avatar alt={`${account.first_name} ${account.last_name}`} >{getNameInitails(`${account.first_name} ${account.last_name}`)}</Avatar>
    )

}

export default AccountPhoto