import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainLayout from './pages/main/MainLayout'
import Home from './pages/main/Home'
import Boards from './pages/main/Boards'
import Profile from './pages/main/profile'
import SearchResults from './pages/search'

import DefaultLayout from './pages/DefaultLayout'
import Login from './pages/Login'
import Register from './pages/Register'
import NoPage from './pages/NoPage'


import PropertyLayout from './pages/property/PropertyLayout';
import AddProperty from './pages/property/AddProperty';
import DetailsPage from './pages/property/DetailsPage';
import SearchLayout from './pages/main/SearchLayout';


//main app

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path="boards" element={<Boards />} />
        <Route path="profile" element={<Profile />} />
        <Route path="details" element={<DetailsPage />} />
        <Route path="*" element={<NoPage />} />
      </Route>
      <Route path="/search/" element={<SearchLayout />}>
        <Route path="singapore" element={<SearchResults />} />
        <Route path="*" element={<NoPage />} />
      </Route>   
      <Route path="/property/" element={<PropertyLayout />}>
        <Route path="add" element={<AddProperty />} />
        <Route path="*" element={<NoPage />} />
      </Route>      
      <Route path="/auth/" element={<DefaultLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
      </Route>
      <Route path="*" element={<NoPage />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
