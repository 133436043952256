
import * as React from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { propertyStatusNames } from '../../../services/PropertyService';

const PropertiesTab = ({ type, setType}) => {

  const navigate = useNavigate(); 

  const GetButtons = () => {
    switch(type) {
      case propertyStatusNames.available:
        return (
          <Stack direction='row' spacing={2}>
              <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Published</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('D')}>Draft</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
          </Stack>
        )
      case propertyStatusNames.inReview:
        return (
          <Stack direction='row' spacing={2}>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
              <Button sx={{textTransform: 'none'}} variant='contained' size='small'>In Review</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('D')}>Draft</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
          </Stack>
        )
      case propertyStatusNames.drafts:
        return (
          <Stack direction='row' spacing={2}>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
              <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Draft</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
          </Stack>
        )
      case propertyStatusNames.sold:
        return (
          <Stack direction='row' spacing={2}>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('D')}>Draft</Button>
              <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Sold</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
          </Stack>
        )   
        case propertyStatusNames.notAvailable:
        return (
          <Stack direction='row' spacing={2}>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('D')}>Draft</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
              <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Unpublished</Button>
          </Stack>
        )
    }
  }

    return (
      <Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <GetButtons />
          <Button sx={{textTransform: 'none', m:2}} variant="outlined" size='small' startIcon={<AddIcon />} onClick={() => navigate('/property/add')} >
            Add Property
          </Button>
        </Stack>
      </Box>
    )

}

export default PropertiesTab;