
import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Stack, Grid, Button, LinearProgress } from '@mui/material';

import { styled } from '@mui/system';
import AccountTab from './tabs/AccountTab';
import BookmarkTab from './tabs/BookmarkTab';
import RecentTab from './tabs/RecentTab';
import PropertiesTab from './tabs/PropertiesTab';
import PropertiesData from './data/PropertiesData';

import getBookmarks from '../../services/BookmarkService';
import {
  getPublishedProperties,
  getClosedProperties,
  getDraftProperties,
  getInReviewProperties,
  getNotAvailableProperties,
  getRecentProperties,
  propertyStatusNames
} from '../../services/PropertyService';
import StatusUpdateDialog from './components/StatusUpdateDialog';
import PublishPropertyDialog from './components/PublishPropertyDialog';
import LoginRequiredModal from '../../property/components/LoginRequiredModal';
import { isAuthenticated } from '../../../utils/session';
import ChatTab from './tabs/ChatTab';
import { useQueryParams } from '../../../utils/ext';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const DEF_DELAY = 5000;
const SNAKBAR_POSITION = {
  vertical: 'top',
  horizontal: 'right',
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const CustomTab = styled(Tab)({
  root: {
    textTransform: "none"
  }
});

export default function Profile() {

  const [value, setValue] = React.useState(0);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [bookmarks, setBookmarks] = React.useState([]);
  const [recentProperties, setRecentProperties] = React.useState([]);

  const [updateStatus, setUpdateStatus] = React.useState(null);
  const [publishProperty, setPublishProperty] = React.useState(null);


  const [type, setType] = React.useState(propertyStatusNames.available);
  const [isLoading, setIsLoading] = React.useState(false)

  const [draftProperties, setDraftProperties] = React.useState([]);
  const [inReviewProperties, setInReviewProperties] = React.useState([]);
  const [closedProperties, setClosedProperties] = React.useState([]);
  const [publishedProperties, setPublishedProperties] = React.useState([]);
  const [notAvailableProperties, setNotAvailableProperties] = React.useState([]);
  const [showLogin, setShowLogin] = React.useState(false);
  const isLogin = React.useRef(isAuthenticated());

  const { tab } = useQueryParams(); 

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSuccess = (msg) => {
    setSuccessMessage(msg);
  }

  const onError = (msg) => {
    setErrorMessage(msg);
  }

  const onEdit = (property) => {
    console.log('onEdit', property)
    navigate(`/property/add?edit=${property.id}`)
  }

  React.useEffect(() => {

    switch (type) {
      case propertyStatusNames.available:

        getPublishedProperties(
          (list) => {
            setPublishedProperties(list);
          },
          (error) => {
            onError(error)
          }
        )
        break;

      case propertyStatusNames.inReview:
        getInReviewProperties(
          (list) => {
            setInReviewProperties(list);
          },
          (error) => {
            onError(error)
          }
        )
        break;

      case propertyStatusNames.drafts:
        getDraftProperties(
          (list) => {
            setDraftProperties(list);
          },
          (error) => {
            onError(error)
          }
        )
        break;

      case propertyStatusNames.sold:
        getClosedProperties(
          (list) => {
            setClosedProperties(list);
          },
          (error) => {
            onError(error)
          }
        )
        break;

      case propertyStatusNames.notAvailable:
        getNotAvailableProperties(
          (list) => {
            setNotAvailableProperties(list);
            setIsLoading(false);
          },
          (error) => {
            onError(error);
            setIsLoading(false);
          }
        )
        break;
    }

  }, [type])

  React.useEffect(() => {
    switch (value) {
      case 0:

        break;

      case 1:
        setIsLoading(true);
        getBookmarks(
          (list) => {
            console.log('list', list);
            setBookmarks(list);
            setIsLoading(false);
          },
          (error) => {
            onError(error)
          }
        )
        break;

      case 2:
        setIsLoading(true);
        getRecentProperties(
          (list) => {
            setRecentProperties(list);
            setIsLoading(false);
          },
          (error) => {
            onError(error);
            setIsLoading(false);
          }
        )
        break;

      default:

        break;

    }
  }, [value])

  React.useEffect(() => {

    switch(tab) {
      case 'profile':
        setValue(0);
      break

      case 'bookmarks':
        setValue(1);
      break

      case 'recent':
        setValue(2);
      break

      case 'properties':
        setValue(3);
      break

      case 'chat':
        setValue(5);
      break
    }

  }, [])

  if (!isLogin.current) {
    navigate('/');
    return null;
  }

  const onCloseError = () => setErrorMessage(null);
  const onCloseSuccess = () => setSuccessMessage(null);

  const onReloadAvailableProperties = () => {
    getPublishedProperties(
      (list) => {
        setPublishedProperties(list);
      },
      (error) => {
        onError(error)
      }
    )
  }

  const onReloadNotAvailableProperties = () => {
    setType(propertyStatusNames.inReview)
  }

  const GetProgress = () => {
    if (isLoading) {
      return <LinearProgress />
    }
    return null
  }

  const onUpdateStatus = (property) => {
    setUpdateStatus(property);
  }

  const onUpdatePublish = (property) => {
    setPublishProperty(property)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Tabs value={value} onChange={handleChange} centered variant="fullWidth">
        <CustomTab label="Account" sx={{ textTransform: false }} {...a11yProps(0)} />
        <CustomTab label="Bookmarks" {...a11yProps(1)} />
        <CustomTab label="Recent History" {...a11yProps(2)} />
        <CustomTab label="My Properties" {...a11yProps(3)} />
        <CustomTab label="Transactions" {...a11yProps(4)} />
        <CustomTab label="Messages" {...a11yProps(5)} />
        <CustomTab label="Settings" {...a11yProps(6)} />
      </Tabs>
      <Box sx={{ width: '100%', p: 1 }}>
        <GetProgress />
      </Box>
      <TabPanel value={value} index={0}>
        <AccountTab onSuccess={onSuccess} onError={onError} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={2} >
          <BookmarkTab bookmarks={bookmarks} onSuccess={onSuccess} setShowLogin={setShowLogin} onError={onError} />
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Stack direction='row-reverse' sx={{ p: 2 }}>
          <Button variant='outlined' size='small'>Clear History</Button>
        </Stack>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <RecentTab properties={recentProperties} setShowLogin={setShowLogin} onSuccess={onSuccess} onError={onError} />
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PropertiesTab
          type={type}
          setType={setType} />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <PropertiesData
            type={type}
            publishedProperties={publishedProperties}
            inReviewProperties={inReviewProperties}
            draftProperties={draftProperties}
            closedProperties={closedProperties}
            notAvailableProperties={notAvailableProperties}
            onUpdateStatus={onUpdateStatus}
            onUpdatePublish={onUpdatePublish}
            onEdit={onEdit} />
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <ChatTab onSuccess={onSuccess} onError={onError} />
      </TabPanel>
      <Snackbar anchorOrigin={SNAKBAR_POSITION} open={successMessage != null} autoHideDuration={DEF_DELAY} onClose={onCloseSuccess}>
        <Alert onClose={onCloseSuccess} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={SNAKBAR_POSITION} open={errorMessage != null} autoHideDuration={DEF_DELAY} onClose={onCloseError}>
        <Alert onClose={onCloseError} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <StatusUpdateDialog property={updateStatus} setOpen={setUpdateStatus} onReloadData={onReloadAvailableProperties} onSuccess={onSuccess} onError={onError} onEdit={onEdit} />
      <PublishPropertyDialog property={publishProperty} setOpen={setPublishProperty} onReloadData={onReloadNotAvailableProperties} onSuccess={onSuccess} onError={onError} onEdit={onEdit} />
      <LoginRequiredModal open={showLogin} setOpen={setShowLogin} />
    </Box>
  );
}