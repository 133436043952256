import Moment from 'moment';
import moment from 'moment-timezone';

export const getRoleId = (r) => {
    if(r === 'Member') {
      return "U";
    }
    return "A";
  }

export const getRoleName = (r) => {
    if(r === 'U') {
      return "Member";
    }
    return "Admin";
}

export default getRoleName

export const dateToShowFormat = (value) => {
  try{
    // Moment.locale('en');
    return Moment(value).local().format('ddd DD MMM YYYY hh:mm a');
  }catch (e) {
    console.log(e);
  }
  return value;
} 

export const dateToShowFormat2 = (value) => {
  try{
    // Moment.locale('en');
    return Moment(value).format('dddd Do MMM');
  }catch (e) {
    console.log(e);
  }
  return value;
} 

export const dateToShowDateFormat = (value) => {
  try{
    // Moment.locale('en');
    return Moment(value).format('dddd, MMM hh:mm a');
  }catch (e) {
    console.log(e);
  }
  return value;
} 

export const dateToShowTimeFormat = (value) => {
  try{
    // Moment.locale('en');
    return Moment(value).format('hh:mm a');
  }catch (e) {
    console.log(e);
  }
  return value;
} 

export const dateToServerFormat = (date, time) => {
  try{
    // Moment.locale('en');
    console.log('dateToServerFormat', date, time);
    const utc = Moment(`${date} ${time}`).utc();
    return `${utc.format("YYYY-MM-DD")}T${utc.format('HH:mm')}:00Z`
  }catch (e) {
    console.log(e);
  }
  return `${date}T${time}`;
} 

export function getTimeZone(){
  return moment.tz.guess();
}

export const getDateTimeNow = () => {
    const now = Moment().format("YYYY-MM-DDTHH:mm:ss");
    console.log('now', now);
    return now;
}

export const getDateTimeForFile = () => {
  const now = Moment().format("YYYY_MM_DD_HH_mm_ss");
  console.log('now', now);
  return now;
}

export const getTimeNow = () => {
  
  const date = Moment()
    .add(2, 'minutes')

  return date.format("HH:mm");

}

export const getDateNow = () => {
  
  const date = Moment()
    .add(2, 'minutes')

  return date.format("YYYY-MM-DD");

}

export function useQueryParams() {
  const params = new URLSearchParams(
    window ? window.location.search : {}
  );

  return new Proxy(params, {
      get(target, prop) {
          return target.get(prop)
      },
  });
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const DEF_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const getListOfDaysInMonth = () => {
  const days = [];
  const from = moment().startOf('month');
  const to = moment().startOf('month').add(1, 'months');
  for (let m = moment(from); m.isBefore(to); m.add(1, 'days')) {
    const monthStart = m.clone().startOf('day').utc().format(DEF_FORMAT)
    const monthEnd = m.clone().endOf('day').utc().format(DEF_FORMAT)
    const name = m.format('DD').substring(0,3)

    days.push({
      'start': monthStart,
      'end': monthEnd,
      'name': name
    });
  }
  console.log('getListOfDaysInMonth', days);
  return days;
};

export const getListOfDaysInWeek = () => {

  const currentDate = moment();
  const start = currentDate.clone().startOf('week');
  const days = [];
  for (let i = 0; i <= 6; i++) {
    const monthStart = start.clone().startOf('day').utc().format(DEF_FORMAT)
    const monthEnd = start.clone().endOf('day').format(DEF_FORMAT)
    const name = start.format('dddd').substring(0,3)

    days.push({
      'start': monthStart,
      'end': monthEnd,
      'name': name
    });
    start.add(1, 'days')
  };

  console.log('getListOfDaysInWeek', days);
  return days;
}

export const getListOfMonthsInYear = () => {

  const start = moment().startOf('year');
  const days = [];
  for (let i = 0; i < 12; i++) {
    start.add(1, 'month')
    const monthStart = start.startOf('month').utc().format(DEF_FORMAT);
    const monthEnd = start.endOf('month').utc().format(DEF_FORMAT);
    const name = start.format('MMMM').substring(0, 3);
    days.push({
      'start': monthStart,
      'end': monthEnd,
      'name': name
    });
  };
  console.log('getListOfMonths', days);
  return days;
}

export const checkIsBefore = (date1, date2) => {
  console.log('checkIsBefore', date1, date2)
  if(date1 === date2) {
    return false;
  }
  return moment(date1).isBefore(moment(date2)); 
}


export const checkTimeIsBefore = (date1, date2) => {
  console.log('checkTimeIsBefore', date1, date2)
  if(date1 === date2) {
    return false;
  }
  return moment(date1, 'HH:mm').isBefore(moment(date2, 'HH:mm')); 
}


export const isBeforeToday = (date1) => {
  return moment(date1).isBefore(moment()); 
}


export const getTimePlusMins = (mins) => {
  
  const date = Moment()
    .add(mins, 'minutes')

  return date.format("HH:mm");

}

export const getTimePlusHours = (hours) => {
  
  const date = Moment()
    .add(hours, 'hours')

  return date.format("HH:mm");
}

export const getDatePlusMins = (mins) => {
  
  const date = Moment()
    .add(mins, 'minutes')

    return date.format("YYYY-MM-DD");

}

export const getDatePlusHours = (hours) => {
  
  const date = Moment()
    .add(hours, 'hours')

  return date.format("YYYY-MM-DD");
}

export const dateFromFromServer = (value) => {
  try{
    // Moment.locale('en');
    return Moment(value).local().format('YYYY-MM-DD');
  }catch (e) {
    console.log(e);
  }
  return value;
} 
export const timeFromFromServer = (value) => {
  try{
    // Moment.locale('en');
    return Moment(value).local().format('HH:mm');
  }catch (e) {
    console.log(e);
  }
  return value;
} 

export const fileToDate = (name) => {

  try{
    const date = name.substring(6, 23)
    const now = Moment(date, 'YYYY-MM-DD-HHmmss').format("DD MMMM, hh:mm:ss a");
    console.log('now', now);
    return now;
  }catch (e) {
    console.log(e);
  }

  return ""
}


export const getNameInitails = (name) => {
  return name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
}

export const removeHtmlTags = (str) => {
  if ((str===null) || (str==='')) {
    return '';
  }else {
    str = str?.toString();
  }
        
  return str?.replace( /(<([^>]+)>)/ig, '');
}

export const formatNumber = (number) =>{
    if(number == null) {
      return '-----'
    }
    if(number === 0) {
      return '-----'
    }
    let str = String(number)
  
    if (number < 1000) return str
  
    let result = ''
  
    for (let i = Math.floor(str.length / 3); i >= 0; i--) {
      let start = str.length - 3 * (i + 1)
      let end = start + 3
  
      result +=
        (end ? str.slice(Math.max(0, start), end) : '') + (end && i ? ',' : '')
    }
  
    return result
}