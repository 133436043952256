import * as React from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';

import { useForm, Controller } from "react-hook-form";


import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, Box } from '@mui/system';
import Modal from '@mui/base/Modal';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { apiUrl, apiPostUnsecure, apiCallPostWithForm, apiPostSecure } from "../../../../utils/api";
import {getAccessToken} from "../../../../utils/session";

const ChangePasswordModal = ({open, setOpen, onSuccess, onError}) => {

    const [isLoading, setLoading] = React.useState(false);

    const {
      register,
      handleSubmit,
      setError,
      clearErrors,
      reset,
      setValue,
      formState: { errors },
    } = useForm();
    

    React.useEffect(() => {
        if(open) {
            console.log('token', getAccessToken());
            setValue('old_password', null);
            setValue('new_password', null);
            setValue('repeat_password', null);
        }
    }, [open])

    const showFormError = (name, error) => {
      setError(name, {
          type: "server",
          message: error
      });
      setTimeout(() => {
          clearErrors();
      }, 3000);
    }  

    async function updatePassword(data) {

        setTimeout(() => {
          clearErrors();
        }, 3000);
        
        if(data.new_password !== data.repeat_password) {
          showFormError('new_password', 'Repeat password not matching!');
          return
        }
       
        console.log('updatePassword', data);
        setLoading(true);

        apiPostSecure(
          apiUrl.resetPassword,
          data,
          (response) => {
            setLoading(false);
            setValue('old_password', null);
            setValue('new_password', null);
            setValue('repeat_password', null);
            setOpen(false);
            onSuccess('Password updated!');
          },
          (errorMsg) => {
            setLoading(false);
            console.log('error', errorMsg)
            showFormError('new_password', errorMsg);
          }
        );
      }


  return (
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
          <Grid container justifyContent="flex-end">
              <Typography component="h1" variant="h5" sx={{mt:1, mx: 4}}>
                  Update Password
              </Typography>         
            <Button  variant="text" onClick={() => setOpen(false)}><CancelRoundedIcon color="primary" fontSize="large"  /></Button>
          </Grid>
          <form id="hook-form" onSubmit={handleSubmit(updatePassword)}>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                      <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            type='password'
                            id="old_password"
                            label="Current Password"
                            name="old_password"
                            autoComplete="none"
                            {...register("old_password", { required: "Enter current password" })}
                            />
                      </Grid>
                      <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            type='password'
                            id="new_password"
                            label="New Password"
                            name="new_password"
                            autoComplete="none"
                            {...register("new_password", { required: "Enter new password" })}
                          />
                      </Grid>         
                      <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            type='password'
                            id="repeat_password"
                            label="Repeat new Password"
                            name="repeat_password"
                            autoComplete="none"
                            {...register("repeat_password", { required: "Repeat new password" })}
                          />
                      </Grid>                                    
                  </Grid>
                  <LoadingButton fullWidth size="large"  form="hook-form" type="submit" variant="contained" loading={isLoading} sx={{ mt: 3, mb: 2 }}>
                      Update
                  </LoadingButton>
                  <Grid container justifyContent="center">
                      <Grid item>
                          <Typography variant='body' color='error'>{errors.new_password && <p>{errors.new_password.message}</p>}</Typography>
                      </Grid>
                  </Grid>
              </Box>
          </form>
        </Box>
      </StyledModal>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};


const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: 400,
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});

export default ChangePasswordModal;