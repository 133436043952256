
import * as React from 'react';
import { useNavigate, Redirect, useLocation, Link as RouterLink } from 'react-router-dom'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Stack, Grid, Button, LinearProgress, Alert } from '@mui/material';

import { styled } from '@mui/system';
import { getHomeProperties, searchProperty } from '../services/PropertyService';
import SearchBar from '../main/components/SearchBar';
import PropertyCard from '../main/components/PropertyCard';
import SearchBarCompact from './components/SearchBarCompact';
import LoginRequiredModal from '../property/components/LoginRequiredModal';

const DEF_DELAY = 5000;
const SNAKBAR_POSITION = {
  vertical: 'top',
  horizontal: 'right',
}

export const GetPropertyContent = ({properties, onSuccess, onError, setShowLogin}) => {
    const data = [];
    properties.forEach((item) => 
        data.push(
            <Grid item xs={3}>
                <PropertyCard property={item} onSuccess={onSuccess} onError={onError} setShowLogin={setShowLogin}/>                    
            </Grid>
        )
    )
    return data
}

export default function SearchResults() {
    
    const [successMessage, setSuccessMessage] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false)
    const [properties, setProperties] = React.useState([]);
    const [loadData, setLoadData] = React.useState(true);
    const isSearching = React.useRef(false);
    const [showLogin, setShowLogin] = React.useState(false);

    React.useEffect(() => {
        if(!loadData) {
            return
          }
          setLoadData(false);
      }, [loadData])

    const location = useLocation();

    console.log('state', location);

    const onSuccess = (msg) => {
        setSuccessMessage(msg);
    }
    
    const onError = (msg) => {
        setErrorMessage(msg);
    }
    
    const onDetails = (property) => {
      console.log('onDetails', property)
    }

    const onSearch = (query) => {
        console.log('onSearch', isSearching.current);
        if(isSearching.current) {
          return;
        }
        setIsLoading(true);
        isSearching.current = true;
        searchProperty(
          query,
          (response) => {
            setIsLoading(false);
            console.log('response', response);
            setProperties(response);
            setTimeout(() => {isSearching.current = false}, 3000);
          },
          (error) => {
            setIsLoading(false);
            onError(error);
            setTimeout(() => {isSearching.current = false}, 3000);
          }  
        )
    }

      
    const onCloseError = () => setErrorMessage(null);
    const onCloseSuccess = () => setSuccessMessage(null);

    const GetProgress = () => {
      if(isLoading) {
        return <LinearProgress />
      }
      return null
    }

    return (
      <Box sx={{ flexGrow: 1 }}>
        <Snackbar anchorOrigin={SNAKBAR_POSITION} open={successMessage!=null} autoHideDuration={DEF_DELAY} onClose={onCloseSuccess}>
          <Alert onClose={onCloseSuccess} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={SNAKBAR_POSITION} open={errorMessage!=null} autoHideDuration={DEF_DELAY} onClose={onCloseError}>
          <Alert onClose={onCloseError} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>  
        <Box>
            <SearchBarCompact onSearch={onSearch} state={location.state} />
        </Box>  
        <Box sx={{ width: '100%', p:1 }}>
          <GetProgress />
        </Box>        
        <Box>
            <Typography variant='h6' sx={{ marginTop: '20px'}}>Search Results</Typography>
            <Grid container spacing={2} sx={{  marginTop: '20px'}}>
                <GetPropertyContent properties={properties} onSuccess={onSuccess} onError={onError} setShowLogin={setShowLogin}/>
            </Grid>
        </Box>
        <LoginRequiredModal open={showLogin} setOpen={setShowLogin} />
      </Box>
    );
}