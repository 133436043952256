import React, { Component } from "react";
import { Box, Grid, List, ListItemText, Stack } from "@mui/material";
import { dateToShowDateFormat } from "../../../../utils/ext";

const GetMessage = ({ message, loginUserId }) => {

    if (message.sender.accountId != loginUserId.current) {
        return (
            <Stack key={`message-${message.id}`} direction='row' sx={{ m: 1 }}>
                <Box>
                    <Box sx={{ backgroundColor: '#e6ffe6', border: 1, borderColor: '#ccffcc', borderRadius: '16px', p: 1 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <ListItemText align="left" primary={message.text}></ListItemText>
                            </Grid>
                        </Grid>
                    </Box>
                    <ListItemText align="left" secondary={dateToShowDateFormat(message.timestamp)} sx={{ ml: 1 }}></ListItemText>
                </Box>
            </Stack>
        )
    }

    return (
        <Stack key={`message-${message.id}`} direction='row-reverse' sx={{ m: 1 }}>
            <Box>
                <Box sx={{ backgroundColor: '#e6f2ff', border: 1, borderColor: '#cce6ff', borderRadius: '16px', p: 1 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <ListItemText align="right" primary={message.text}></ListItemText>
                        </Grid>
                    </Grid>
                </Box>
                <ListItemText align="right" secondary={dateToShowDateFormat(message.timestamp)}></ListItemText>
            </Box>
        </Stack>
    )
}

const GetMessages = ({ loginUserId, messages }) => {
    const data = []
    messages.map((item) => {
        data.push(<GetMessage message={item} loginUserId={loginUserId} />)
    })

    return data
}

// class ChatMessageView extends Component {

//     constructor() {
//         super();
//         this.messagesEndRef = React.useRef(null);
//     }
//     componentDidMount() {
//       this.scrollToBottom();
//     }
  
//     componentDidUpdate() {
//       this.scrollToBottom();
//     }
  
//     scrollToBottom() {
//       console.log('messagesEndRef', this.messagesEndRef);  
//       this.messagesEndRef?.scrollIntoView({ behavior: 'smooth' });
//     }
  
//     render() {
//       return (
//         <div ref={el => { this.el = el; }}>
//             <List>
//                 <GetMessages messages={this.props.messages} loginUserId={this.props.loginUserId} />
//             </List>
//             <div ref={this.messagesEndRef} />
//         </div>
//       )
//     }
//   }


const ChatMessageView = ({messages, loginUserId}) => {

    const messagesEndRef = React.useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
    }
  
    React.useEffect(() => {
        scrollToBottom()
    }, [messages]);

    return (
        <div>
            <List>
                <GetMessages messages={messages} loginUserId={loginUserId} />
            </List>
            <div ref={messagesEndRef} />
        </div>
      )
  }

export default ChatMessageView;