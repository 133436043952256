import React from 'react';
import { useForm, Controller } from "react-hook-form";

import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { LoadingButton } from '@mui/lab';
import { Chat, Send } from '@mui/icons-material';
import { Button, CircularProgress, Stack } from '@mui/material';
import { apiCallSecureGet, apiPostSecure, apiUrl } from '../../../../utils/api';
import { accountId } from '../../../../utils/session'
import { getNameInitails, dateToShowDateFormat } from '../../../../utils/ext';
import AccountPhoto from '../components/AccountPhoto';
import ChatMessageView from '../components/ChatMessageView';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '85vh'
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
        height: '60vh',
        overflowY: 'scroll'
    }
});



const GetUserItem = ({ loginUserId, chat, selectedUser, onUserSelect }) => {

    let user = null;

    if (loginUserId.current == chat.sender.accountId) {
        user = chat.receiver
    } else {
        user = chat.sender
    }
    console.log('user', `${user.first_name} ${user.last_name}`);

    if (selectedUser != null && user.id == selectedUser.id) {
        return (
            <Button variant='contained'
                onClick={() => {
                    onUserSelect(user);
                }}
                sx={{ textTransform: 'none' }}
                fullWidth>
                <ListItem key={`user-${user.accountId}`}  >
                    <ListItemIcon>
                        <AccountPhoto account={user} /> 
                    </ListItemIcon>
                    <ListItemText primary={`${user.first_name} ${user.last_name}`}></ListItemText>
                </ListItem>
                <Divider />
            </Button>
        )
    }

    return (
        <Button variant='text' color='inherit'
            sx={{ textTransform: 'none' }}
            onClick={() => {
                onUserSelect(user);
            }}>
            <ListItem key={`user-${user.accountId}`}  >
                <ListItemIcon>
                    <AccountPhoto account={user} /> 
                </ListItemIcon>
                <ListItemText primary={`${user.first_name} ${user.last_name}`}></ListItemText>
            </ListItem>
            <Divider />
        </Button>
    )
}



const GetUsers = ({ loginUserId, users, selectedUser, onUserSelect }) => {
    const data = []
    users.map((item) => {
        data.push(<GetUserItem loginUserId={loginUserId} chat={item} selectedUser={selectedUser} onUserSelect={onUserSelect} />)
    })
    return data
}

const MINUTE_MS = 5000;

const ChatTab = ({ onSuccess, onError }) => {
    const classes = useStyles();

    const [loadData, setLoadData] = React.useState(true);
    const [userList, setUserList] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [chatMessages, setChatMessages] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [messageSending, setMessageSending] = React.useState(false);
    const loginUserId = React.useRef(accountId());
    const [loadMessages, setLoadMessages] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const apiCalling = React.useRef(false);
    const chatUser = React.useRef(null);
    const messageCount = React.useRef(0);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
      } = useForm();

    
    const refreshMessages = () => {
        console.log('select', chatUser.current, apiCalling.current);

            if (chatUser.current == null || apiCalling.current) {
                return;
            }
            apiCalling.current = true
            const data = {
                user: chatUser.current?.accountId
            }

            apiPostSecure(apiUrl.chatContacts,
                data,
                (response) => {
                    apiCalling.current = false;
                    try{
                        console.log('response', messageCount.current, response.chats.length);
                        // setChatMessages(response.chats);
                        if(messageCount.current != response.chats.length) {
                            messageCount.current = response.chats.length;
                            setChatMessages(response.chats);
                        }
                    }catch(e) {
                        console.log('e', e)
                    }
                },
                (error) => {
                    apiCalling.current = false;
                    console.log('error', error);
                    onError(error);
                }
            )
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            refreshMessages();
        }, MINUTE_MS);
        return () => clearInterval(interval); 
      }, [])

    React.useEffect(() => {
        setTimeout(() => setErrorMessage(null), 4000)
    }, [errorMessage])

    React.useEffect(() => {

        if (!loadData) {
            return
        }
        setLoadData(false)
        
        apiCallSecureGet(apiUrl.chatContacts,
            (response) => {
                console.log('response', response)
                setUserList(response.chats)
            },
            (error) => {
                console.log('error', error)
                onError(error)
            }
        )

    }, [loadData])


    React.useEffect(() => {
        if (selectedUser == null) {
            return;
        }
        console.log('select', selectedUser);
        const data = {
            user: selectedUser?.accountId
        }
        apiPostSecure(apiUrl.chatContacts,
            data,
            (response) => {
                console.log('response', response);
                messageCount.current = response.chats.length;
                setChatMessages(response.chats);
            },
            (error) => {
                console.log('error', error)
                onError(error)
            }
        )
    }, [selectedUser, loadMessages])

    const onUserSelect = (user) => {
        console.log('onUserSelect', user);
        chatUser.current = user;
        setSelectedUser(user)
    }

    const sendMessage = (data) => {

        if(!data.message) {
            setErrorMessage('Type message to send')
            return
        }

        setMessageSending(true);

        const requestData = {
            message: data.message,
            receiver: selectedUser?.accountId
        }
      
          apiPostSecure(
            apiUrl.chatMessage,
            requestData,
            (response) => {
                setTimeout(() => {
                    setMessageSending(false);
                    setValue('message', null);
                    setLoadMessages(true);
                    onSuccess('Message sent');
                }, 1000)
            },
            (errorMsg) => {
                setMessageSending(false);
                console.log('error', errorMsg)
                setErrorMessage(errorMsg);
            }
        )

    }

    const GetMessageView = () => {
        if (selectedUser) {

            if (isLoading) {
                return (
                    <Box>
                        <ListItem key={`user-${selectedUser.id}`}  >
                            <ListItemIcon>
                                <AccountPhoto account={selectedUser} /> 
                            </ListItemIcon>
                            <ListItemText primary={`${selectedUser.first_name} ${selectedUser.last_name}`}></ListItemText>
                        </ListItem>
                        <List className={classes.messageArea}>
                            <List>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ minHeight: '50vh' }}
                                >
                                    <CircularProgress color='primary' />
                                </Grid>
                            </List>
                        </List>
                    </Box>
                )
            }

            return (
                <Box>
                    <ListItem key={`user-${selectedUser.id}`}  >
                        <ListItemIcon>
                            <AccountPhoto account={selectedUser} /> 
                        </ListItemIcon>
                        <ListItemText primary={`${selectedUser.first_name} ${selectedUser.last_name}`}></ListItemText>
                    </ListItem>
                    <List className={classes.messageArea}>
                        <ChatMessageView messages={chatMessages} loginUserId={loginUserId} />
                    </List>
                </Box>
            )       
        }

        return (
            <Box>
                <ListItem >
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ minHeight: '50vh' }}
                    >
                        <Chat sx={{ fontSize: 80, color: '#aaa' }} />
                        <Typography>Select user to chat</Typography>
                    </Grid>
                </ListItem>
            </Box>

        )
    }

    const GetSendView = () => {
        if (selectedUser) {
            return (
                <Box>
                    <Divider />
                    <form id="contact-form" onSubmit={handleSubmit(sendMessage)}>
                        <Grid container style={{ padding: '20px' }} >
                            <Grid item xs={11} >
                                <TextField sx={{mr:2}} id="outlined-basic-email" label="Type Something" fullWidth {...register("message", { required: "Enter message" })} />
                            </Grid>
                            <Grid xs={1} align="right">
                                <LoadingButton sx={{p: 2, height: 50, width:50}} fullWidth size="large" form="contact-form" type="submit" variant="contained" loading={messageSending} >
                                    <Send />
                                </LoadingButton>
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant='body' color='error'>{errorMessage}</Typography>    
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            )
        }

        return null
    }

    return (
        <Box >
            <Grid container component={Paper} className={classes.chatSection}>
                <Grid item xs={3} className={classes.borderRight500}>
                    <Stack direction='column'>
                        <GetUsers loginUserId={loginUserId} users={userList} selectedUser={selectedUser} onUserSelect={onUserSelect} />
                    </Stack>
                </Grid>
                <Grid item xs={9}>
                    <GetMessageView />
                    <GetSendView />
                </Grid>
            </Grid>
        </Box>
    );
}

export default ChatTab;