import * as React from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';

import { useForm, Controller } from "react-hook-form";


import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, Box } from '@mui/system';
import Modal from '@mui/base/Modal';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import ImagePreview from '../../components/ImagePreview';

import { getUser, saveUser, getProfilePhoto } from "../../../../utils/session";
import { apiUrl, apiPostUnsecure, apiCallPostWithForm } from "../../../../utils/api";
import { showError, showSucess } from '../../../../utils/toast';

const UpdateInfoModal = ({open, setOpen, onSuccess, onError}) => {

    const [isLoading, setLoading] = React.useState(false);
    const [profileImage, setProfileImage] = React.useState(null);
    const [logoUrl, setLogoUrl] = React.useState(null);

    const {
      register,
      handleSubmit,
      setError,
      clearErrors,
      reset,
      setValue,
      formState: { errors },
    } = useForm();
    
    const user = React.useRef(getUser())

    const navigate = useNavigate();  

    React.useEffect(() => {
        if(open) {
            setValue('first_name', user.current.firstName);
            setValue('last_name', user.current.lastName);
            setValue('email', user.current.email);
            setValue('mobile', user.current.mobile);
            setLogoUrl(getProfilePhoto())
        }
    }, [open])

    const showFormError = (name, error) => {
      setError(name, {
          type: "server",
          message: error
      });
      setTimeout(() => {
          clearErrors();
      }, 3000);
    }  

    const companyLogoChangeHandler = (file) => {
      console.log('file', file.name);
      setProfileImage(file)
    }

    async function updateInfo(data) {

        setTimeout(() => {
          clearErrors();
        }, 3000);

        const formData = new FormData();
        formData.append("first_name", data.first_name);
        formData.append("last_name", data.last_name);
        formData.append("email", data.email);
        formData.append("mobile", data.mobile);

        if(profileImage !== null) {
            formData.append("photo", profileImage);
        }

        console.log('sginUp', data);
        setLoading(true);

        apiCallPostWithForm(
          apiUrl.profile,
          formData,
          (response) => {
            setLoading(false);
            setValue('first_name', null);
            setValue('last_name', null);
            setValue('email', null);
            setValue('mobile', null);
            setProfileImage(null);
            saveUser(response);
            setOpen(false);
            onSuccess('Account updated!');
          },
          (errorMsg) => {
            setLoading(false);
            console.log('error', errorMsg)
            setError(errorMsg);
          }
        );
      }


  return (
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
          <Grid container justifyContent="flex-end">
              <Typography component="h1" variant="h5" sx={{m:1}}>
                  Update Account Details
              </Typography>         
            <Button  variant="text" onClick={() => setOpen(false)}><CancelRoundedIcon color="primary" fontSize="large"  /></Button>
          </Grid>
          {/* <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
              <Typography component="h1" variant="h5">
                  Update Account Details
              </Typography>            
          </Box>    */}
          <ImagePreview
            logo={logoUrl}
            onFileSelectSuccess={(file) => companyLogoChangeHandler(file)}
            onFileSelectError={({ error }) => setError('photo', {
              type: "server",
              message: error
          })}
          />
          <form id="hook-form" onSubmit={handleSubmit(updateInfo)}>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                          <TextField
                          autoComplete="given-name"
                          name="first_name"
                          required
                          fullWidth
                          id="first_name"
                          label="First Name"
                          autoFocus
                          {...register("first_name", { required: "Enter First Name" })}
                          />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          <TextField
                          required
                          fullWidth
                          id="last_name"
                          label="Last Name"
                          name="last_name"
                          autoComplete="family-name"
                          {...register("last_name", { required: "Enter Last Name" })}
                          />
                      </Grid>
                      <Grid item xs={12}>
                          <TextField
                          required
                          fullWidth
                          id="mobile"
                          label="Phone number"
                          name="mobile"
                          autoComplete="mobile"
                          {...register("mobile", { required: "Enter phone number" })}
                          />
                      </Grid>
                      <Grid item xs={12}>
                          <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          {...register("email", { required: "Enter email" })}
                          />
                      </Grid>                    
                  </Grid>
                  <LoadingButton fullWidth size="large"  form="hook-form" type="submit" variant="contained" loading={isLoading} sx={{ mt: 3, mb: 2 }}>
                      Update
                  </LoadingButton>
                  <Grid container justifyContent="center">
                      <Grid item>
                          <Typography variant='body' color='error'>{errors.photo && <p>{errors.photo.message}</p>}</Typography>
                      </Grid>
                  </Grid>
              </Box>
          </form>
        </Box>
      </StyledModal>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};


const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: 400,
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});

export default UpdateInfoModal;