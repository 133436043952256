import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Grid, Box, Stack} from '@mui/material'
import PropertyCard from './PropertyCard';
import PropertySliderCard from './PropertySliderCard';

const HomeSlider = ({properties, setShowLogin, onSuccess, onError}) =>
{

    const sliderItems = properties.length > 3 ? 3 : properties.length;
    const items = [];

    for (let i = 0; i < properties.length; i += sliderItems) {
        if (i % sliderItems === 0) {
        items.push(
            <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
                    {properties.slice(i, i + sliderItems).map((da, index) => {
                        return <PropertySliderCard key={index.toString()} setShowLogin={setShowLogin} property={da} onSuccess={onSuccess} onError={onError}/>;
                    })}
            </Stack>
        );
        }
    }   
    return (
        <Carousel animation="slide" autoPlay={false} cycleNavigation timeout={300} sx={{width: 'auto'}}>
            {items}
        </Carousel>
  );
}


export default HomeSlider;