import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { Stack } from '@mui/material';


const rootStyle = {
    width: "170px",
    height: '170px',
    overflow: "hidden",
    position: "relative",
    border: `3px dashed #bbb`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

const buttonStyle = {
    backgroundColor: '#bbb',
    '&:hover': {
        backgroundColor: '#eee'
    },
    height: 24,
}

export const ImagePreviewReset = (props) => {
    const { preview, setPreview, onFileSelectSuccess, onFileSelectError } = props;

    const onSelectFile = (e) => {

        if (e.target.files) {
            const objectUrl = URL.createObjectURL(e.target.files[0]);
            setPreview(objectUrl);
            const file = e.target.files[0];
            const convertInMb = file.size / 1000000
            if (convertInMb > 1) {
                onFileSelectError({ error: "File size cannot exceed more than 1MB" });
                setPreview(null)
            }
            else onFileSelectSuccess(file);
        }

    }

    return (
        // eslint-disable-next-line no-template-curly-in-string
        <Box sx={rootStyle}>
            <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 72, height: 72 }}
            />
            <Button
                variant="contained"
                component="label"
                sx={buttonStyle}
            >

                {/* {console.log("preview", preview)} */}
                {preview ? "Change Image" : "Upload Image"}
                <input
                    type="file"
                    onChange={onSelectFile}
                    hidden
                    accept="image/png, image/gif, image/jpeg"
                />
            </Button>
        </Box>
    )
}

const ImagePreview = (props) => {
    const { logo, onFileSelectSuccess, onFileSelectError } = props;
    const [preview, setPreview] = useState();


    useEffect(() => {
        if (logo) {
            setPreview(logo)
        }
    }, [logo])


    const onSelectFile = (e) => {

        if (e.target.files) {
            const objectUrl = URL.createObjectURL(e.target.files[0]);
            setPreview(objectUrl);
            const file = e.target.files[0];
            const convertInMb = file.size / 1000000
            if (convertInMb > 1) {
                onFileSelectError({ error: "File size cannot exceed more than 1MB" });
                setPreview(logo)
            }
            else onFileSelectSuccess(file);
        }

    }

    return (
        // eslint-disable-next-line no-template-curly-in-string
        <Stack direction='column' justifyContent='center'   alignItems="center" spacing={1} sx={{m:4}}> 
            <Box sx={rootStyle}>
                <Avatar
                    alt="Remy Sharp"
                    src={preview}
                    sx={{ width: 140, height: 140 }}
                />            
            
            </Box>
            <Button
                variant="contained"
                component="label"
                sx={buttonStyle}
                size='small'
            >

                {/* {console.log("preview", preview)} */}
                {preview ? "Change Image" : "Upload Image"}
                <input
                    type="file"
                    onChange={onSelectFile}
                    hidden
                    accept="image/png, image/gif, image/jpeg"
                />
            </Button>
        </Stack>
    )
}

export default ImagePreview