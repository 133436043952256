
import React from 'react';
import TypingImage from '../../assets/typing.jpg'
import SingaporeImage from '../../assets/singapore.jpg'
import { Box, Grid, Typography, Card, CardActions, CardContent, Button, Stack, Alert, Snackbar } from '@mui/material';
import SearchBar from './components/SearchBar'
import PropertyCard from './components/PropertyCard';
import HomeSlider from './components/HomeSlider';
import { getHomeProperties } from '../services/PropertyService';
import LoginRequiredModal from '../property/components/LoginRequiredModal';

const DEF_DELAY = 5000;
const SNAKBAR_POSITION = {
  vertical: 'top',
  horizontal: 'right',
}

export const GetPropertyContent = ({properties, setShowLogin, onSuccess, onError}) => {
    const data = [];
    properties.forEach((item) => 
        data.push(
            <Grid item xs={3}>
                <PropertyCard property={item} onSuccess={onSuccess} onError={onError} setShowLogin={setShowLogin}/>                    
            </Grid>
        )
    )
    return data
}

function Home (){

    const [loadData, setLoadData] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [successMessage, setSuccessMessage] = React.useState(null);
    const [renectProperties, setRecentProperties] = React.useState([]);
    const [showLogin, setShowLogin] = React.useState(false);

    const onSuccess = (msg) => {
      setSuccessMessage(msg);
    }
  
    const onError = (msg) => {
        setErrorMessage(msg);
    }

    React.useEffect(() => {
        
      if(!loadData) {
          return
        }
        setLoadData(false);

        getHomeProperties(
          (data) => {
            setRecentProperties(data);
          },
          (error) => {
            setErrorMessage(error);
          }
        )
    }, [loadData])

    const onCloseError = () => setErrorMessage(null);
    const onCloseSuccess = () => setSuccessMessage(null);

    return (
      <Box>
          <Box sx={{p: 4}}>
            <Box sx={{ flexGrow: 1 }}>
                <Stack sx={{ 
                        backgroundImage: `url(${SingaporeImage})`,
                        height: '80vh',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }} direction='row' justifyContent='center'>
                                <Box>
                                    <Typography variant="h2" textAlign='center' color='#f5f5f5' sx={{ fontWeight: '500'}}>
                                        Singapore's Smarter Property Search
                                    </Typography>
                                    <Typography variant="h4" color='#f5f5f5' textAlign='center'>
                                        Buy, Rent, Sale
                                    </Typography>
                                    <SearchBar />
                                </Box>
                </Stack>
                <Typography variant='h6' sx={{ marginTop: '20px'}}>Featured</Typography>
                <HomeSlider properties={renectProperties} setShowLogin={setShowLogin}  onSuccess={onSuccess} onError={onError}/>
                <Typography variant='h6' sx={{ marginTop: '20px'}}>Newly added</Typography>
                <Grid container spacing={2} sx={{  marginTop: '20px'}}>
                   <GetPropertyContent properties={renectProperties} setShowLogin={setShowLogin} onSuccess={onSuccess} onError={onError}/>
                </Grid>
            </Box>
        </Box>        
          <Snackbar anchorOrigin={SNAKBAR_POSITION} open={successMessage!=null} autoHideDuration={DEF_DELAY} onClose={onCloseSuccess}>
            <Alert onClose={onCloseSuccess} severity="success" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          </Snackbar>
          <Snackbar anchorOrigin={SNAKBAR_POSITION} open={errorMessage!=null} autoHideDuration={DEF_DELAY} onClose={onCloseError}>
            <Alert onClose={onCloseError} severity="error" sx={{ width: '100%' }}>
              {errorMessage}
            </Alert>
          </Snackbar>   
          <LoginRequiredModal open={showLogin} setOpen={setShowLogin} />
      </Box>
    )
}
  
export default Home;