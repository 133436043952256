import React, {useState} from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';

import { Outlet, Link } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TypingImg from '../../assets/typing-2.png'
import { Stack } from '@mui/material';
import SignupModal from './components/SignupModal';
import SignInModal from './components/SigninModal';
import isAuthenticated, {logout, getUserName} from '../../utils/session';
import { getNameInitails } from '../../utils/ext' 
import AccountMenu from './components/AccountMenu';
import Footer from './components/Footer';
import { propertyTypeNames } from '../services/PropertyService';

const DEF_DELAY = 5000;
const SNAKBAR_POSITION = {
  vertical: 'top',
  horizontal: 'right',
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MainLayout = () => {

  const [openSignup, setOpenSignup] = React.useState(false);
  const [openSignin, setOpenSignin] = React.useState(false);

  const [successMessage, setSuccessMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const [anchorFB, setAnchorFB] = useState(null);
  const [anchorFO, setAnchorFO] = useState(null);

  const navigate = useNavigate(); 

  const [isLogin, setIsLogin] = React.useState(isAuthenticated())

  const logoutAccount = () => {
    logout();
    window.location.reload();
  }

  const onSuccess = (msg) => {
    setSuccessMessage(msg);
    if(isAuthenticated()) {
      window.location.reload();
    }
    // setTimeout(() => setSuccessMessage(null), DEF_DELAY)
  }


  const onError = (msg) => {
    setErrorMessage(msg);
    // setTimeout(() => setErrorMessage(null), DEF_DELAY)
  }
  
  const onCloseError = () => setErrorMessage(null);
  const onCloseSuccess = () => setSuccessMessage(null);

  function handleClickFB(event) {
    if (anchorFB !== event.currentTarget) {
      setAnchorFB(event.currentTarget);
    }
  }

  function handleCloseFB(option) {
    setAnchorFB(null);

    let query = null;
    switch(option) {
      case 'flat':
        query = {
            type: propertyTypeNames.residental,
            kinds:['Flat/Apartment'],
            min_value: null,
            max_value: null,
            query: null,
            typeNames: {r1: true},
            bedrooms: [],
            sale_type: 'Sale',
            bedroom_counts: null,
            tabValue: 0
        }

      break;

      case 'house':
        query = {
            type: propertyTypeNames.residental,
            kinds: ['Independant house/Villa'],
            min_value: null,
            max_value: null,
            query: null,
            typeNames: {r2: true},
            bedrooms: [],
            sale_type: 'Sale',
            bedroom_counts: null,
            tabValue: 0
        }
      break;

      case 'office':
        query = {
            type: propertyTypeNames.commercial,
            kinds: ['Office'],
            min_value: null,
            max_value: null,
            query: null,
            typeNames: {c1: true},
            bedrooms: [],
            sale_type: 'Sale',
            bedroom_counts: null,
            tabValue: 0
        }
      break;
    }

    console.log('query', query);
    if(query) {
      navigate('/search/singapore', { state: query });
    }
  }

  const [anchorFR, setAnchorFR] = useState(null);

  function handleClickFR(event) {
    if (anchorFR !== event.currentTarget) {
      setAnchorFR(event.currentTarget);
    }
  }

  function handleCloseFR(option) {
    setAnchorFR(null);

    let query = null;
    switch(option) {
      case 'flat':
        query = {
            type: propertyTypeNames.residental,
            kinds:['Flat/Apartment'],
            min_value: null,
            max_value: null,
            query: null,
            typeNames: {r1: true},
            bedrooms: [],
            sale_type: 'Rent',
            bedroom_counts: null,
            tabValue: 1
        }

      break;

      case 'house':
        query = {
            type: propertyTypeNames.residental,
            kinds: ['Independant house/Villa'],
            min_value: null,
            max_value: null,
            query: null,
            typeNames: {r2: true},
            bedrooms: [],
            sale_type: 'Rent',
            bedroom_counts: null,
            tabValue: 1
        }
      break;

      case 'office':
        query = {
            type: propertyTypeNames.commercial,
            kinds: ['Office'],
            min_value: null,
            max_value: null,
            query: null,
            typeNames: {c1: true},
            bedrooms: [],
            sale_type: 'Rent',
            bedroom_counts: null,
            tabValue: 1
        }
      break;
    }

    console.log('query', query);
    if(query) {
      console.log('query', query);
      navigate('/search/singapore', { state: query });
    }

  }
  
  function handleClickFO(event) {
    if (anchorFO !== event.currentTarget) {
      setAnchorFO(event.currentTarget);
    }
  }

  function handleCloseFO(option) {
    setAnchorFO(null);

    switch(option) {
      case 'property':
        navigate('/property/add')
      break;

      case 'services':

      break;

      case 'chats':
        navigate('/profile?tab=chat')
      break;
    }

  }

  const AccountInfo = () => {

    if(isLogin) {
        return (<AccountMenu onLogout={logoutAccount} />)
    }

    return (
      <Stack direction='row-reverse' sx={{ flexGrow: 0 }}>
          <Button sx={{textTransform: 'none'}}  variant='text' onClick={() => setOpenSignin(true)}>
              Log in
          </Button>
          <Button sx={{textTransform: 'none'}}  variant='text' onClick={() => setOpenSignup(true)} >
              Sign up
          </Button>
      </Stack>
    )
  } 

  return (
    <>
      <AppBar position="static" variant='elevated' sx={{backgroundColor:'#FFF', color: '#1976D2'}}>
        <Container maxWidth="xl"> 
        {/* desktop view  */}
          <Toolbar disableGutters>
            <HomeWorkIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{ // in line css style 
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Property Rental
            </Typography>           
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Box sx={{ flexGrow: 1, mx:4, display: { xs: 'none', md: 'flex' } }}>
                  <Box>
                    <Button
                      aria-owns={anchorFB ? "for-buy" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickFB}
                      onMouseOver={handleClickFB}
                      sx={{textTransform: 'none'}}
                    >
                      For Buyers
                    </Button>
                    <Menu
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      transformOrigin={{ vertical: "top", horizontal: "left" }}
                      anchorEl={anchorFB}
                      open={Boolean(anchorFB)}
                      onClose={handleCloseFB}
                      MenuListProps={{ onMouseLeave: handleCloseFB }}
                      getContentAnchorEl={null}
                    >
                      <MenuItem onClick={() => handleCloseFB('flat')}>Buy Flat/Appartment</MenuItem>
                      <MenuItem onClick={() => handleCloseFB('house')}>Buy House/Villa</MenuItem>
                      <MenuItem onClick={() => handleCloseFB('office')}>Buy Office</MenuItem>
                    </Menu>
                  </Box>
                  <Box>
                    <Button
                      aria-owns={anchorFR ? "for-rent" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickFR}
                      onMouseOver={handleClickFR}
                      sx={{textTransform: 'none'}}
                    >
                      For Renters
                    </Button>
                    <Menu
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      transformOrigin={{ vertical: "top", horizontal: "left" }}
                      anchorEl={anchorFR}
                      open={Boolean(anchorFR)}
                      onClose={handleCloseFR}
                      MenuListProps={{ onMouseLeave: handleCloseFR }}
                      getContentAnchorEl={null}
                    >
                      <MenuItem onClick={() => handleCloseFR('flat')}>Rent Flat/Appartment</MenuItem>
                      <MenuItem onClick={() => handleCloseFR('house')}>Rent House/Villa</MenuItem>
                      <MenuItem onClick={() => handleCloseFR('office')}>Rent Office</MenuItem>
                    </Menu>
                  </Box>
                  <Box>
                    <Button
                      aria-owns={anchorFO ? "for-owners" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickFO}
                      onMouseOver={handleClickFO}
                      sx={{textTransform: 'none'}}
                    >
                      For Owners
                    </Button>
                    <Menu
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      transformOrigin={{ vertical: "top", horizontal: "left" }}
                      anchorEl={anchorFO}
                      open={Boolean(anchorFO)}
                      onClose={handleCloseFO}
                      MenuListProps={{ onMouseLeave: handleCloseFO }}
                      getContentAnchorEl={null}
                    >
                      <MenuItem onClick={() => handleCloseFO('property')}>Post properties for free</MenuItem>
                      <MenuItem onClick={() => handleCloseFO('services')}>Owner services</MenuItem>
                      <MenuItem onClick={() => handleCloseFO('chats')}>See responses</MenuItem>
                    </Menu>
                  </Box>
            </Box>
            
            <AccountInfo />
           
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl"> 
        <SignupModal open={openSignup} setOpen={setOpenSignup} onSuccess={onSuccess} onError={onError}/>
        <SignInModal open={openSignin} setOpen={setOpenSignin} onSuccess={onSuccess} onError={onError}/>
        <Snackbar anchorOrigin={SNAKBAR_POSITION} open={successMessage!=null} autoHideDuration={DEF_DELAY} onClose={onCloseSuccess}>
          <Alert onClose={onCloseSuccess} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={SNAKBAR_POSITION} open={errorMessage!=null} autoHideDuration={DEF_DELAY} onClose={onCloseError}>
          <Alert onClose={onCloseError} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>      
        <Outlet />
        <Footer />
      </Container>
    </>
  )
};

export default MainLayout;