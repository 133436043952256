
import React from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';

import SingaporeImage from '../../assets/singapore.jpg'
import { Box, Grid, Stepper, Step, StepLabel, Typography, TextField, Stack, Autocomplete, LinearProgress, Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import Slider from '@mui/material/Slider';

import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import TickIcon from '../../assets/tick.png'

import GetResidentalProperties, {GetCommercialProperties} from './components/PropertyTypes';

import isAuthenticated, {logout, getUserName, accountId} from '../../utils/session';
import { removeHtmlTags, useQueryParams } from '../../utils/ext';
import { getDateNow } from '../../utils/ext';
import LocationPickerModal from './components/LocationPickerModal';
import PlaceAutoComplete, { getAddressFromPlace } from './components/PlaceAutoComplete';
import { useForm, Controller  } from "react-hook-form";
import { apiCallPostWithForm, apiCallSecureDelete, apiCallSecureDeleteUrl, apiPostSecure, apiUrl } from '../../utils/api';
import { FileUploader } from 'react-drag-drop-files';
import {ProgressDialog} from '../../utils/diloag';
import { getPropertyDetails } from '../services/PropertyService';

const TYPE_RESIDENTAL = 'R'
const TYPE_COMMERCIAL = 'C'
const PROPERTY_SALE = 'Sale'
const PROPERTY_RENT = 'Rent'

const AGE_0_1 = '0-1'
const AGE_2_5 = '2-5'
const AGE_5_10 = '5-10'
const AGE_10p = '10p'

const FURNISHED = 'Furnished'
const SEMI_FURNISHED = 'Partial Furnished'
const UN_FURNISHED = 'Unfurnished'

const steps = ['Basic Details', 'Location Details', 'Property Profile', 'Photos', 'Pricing & others', 'submit'];
const fileTypes = ["JPEG", "PNG", "JPG", "WEBP"];

const countries = [
    {
        value: 'Singapore',
        label: 'Singapore'
    }
]

const cities = [
    {
        value: 'Singapore',
        label: 'Singapore'
    }
]

const currencies = [
    {
        value: 'SGD',
        label: 'SGD (S$)',
    },    
    // {
    //     value: 'MYR',
    //     label: 'MYR (M$)',
    // },
    // {
    //   value: 'USD',
    //   label: 'USD ($)',
    // },
    // {
    //   value: 'Yuan',
    //   label: 'Yuan (¥)',
    // },
    // {
    //   value: 'JPY',
    //   label: 'JPY (¥)',
    // },
];
const getFloorOptions = () => {

    const floors = [];
    floors.push('Basement');
    floors.push('Lower Ground');
    floors.push('Ground');
    for(let i=1; i < 61; i++) {
        floors.push(`${i}`)
    }

    return floors;
}


const getFloorTotalOptions = () => {

    const floors = [];
    for(let i=1; i < 61; i++) {
        floors.push(`${i}`)
    }

    return floors;
}

const getBedroomOptions = () => {

    const list = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
    

    return list;
}



function AddProperty (){
    const propertyId = React.useRef(null)
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [propertySeleType, setPropertySaleType] = React.useState(null);
    const [propertyType, setPropertyType] = React.useState(null);
    const [propertyTypeName, setPropertyTypeName] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [openLocationPicker, setOpenLocationPicker] = React.useState(false);
    const [selectedPlace, setSelectedPlace] = React.useState(null);
    const [savingDraft, setSavingDraft] = React.useState(false)
    const [loadingDialog, setLoadingTitle] = React.useState(null)

    const [propertyPhotos, setPropertyPhotos] = React.useState([])
    const [addedPhotos, setAddedPhotos] = React.useState([])

    const [furnishedStatus, setFurnishedStatus] = React.useState(null);
    const [propertyAge, setPropertyAge] = React.useState(null);
    const [currencyValue, setCurrencyValue] = React.useState('SGD');

    const [floorAt, setFloorAt] = React.useState('1');
    const [floorTotal, setFloorTotal] = React.useState('1');

    const [availableDate, setAvailableDate] = React.useState(getDateNow());

    const [contactAgent, setContactAgent] = React.useState(true);
    const [priceNegotiable, setPriceNegotiable] = React.useState(false);
    const [includeElectricity, setIncludeElectricity] = React.useState(false);
    const [includeWater, setIncludeWater] = React.useState(false);

    const [rentFamilty, setRentFamilty] = React.useState(true);
    const [rentSingleMen, setRentSingleMen] = React.useState(true);
    const [rentSingleWoman, setRentSingleWoman] = React.useState(true);

    const floorCount = React.useRef(getFloorOptions());
    const totalFloorCount = React.useRef(getFloorTotalOptions());
    const addressObj = React.useRef({
        country: null,
        city: null,
        address: null,
        landmark: null,
        floor: null,
    })

    const isLogin = React.useRef(isAuthenticated());
    const loginUserId = React.useRef(accountId());

    const navigate = useNavigate(); 

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        control,
        onChange,
        formState: { errors },
      } = useForm();

    const { edit } = useQueryParams();  

    React.useEffect(()=> {
        setTimeout(() => setErrorMessage(null), 5000)
    }, [errorMessage])

    const isStepOptional = (step) => {
        return step === 10;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    React.useEffect(() => {
        setValue('bedrooms', 1);
        setValue('bathrooms', 1);
        setValue('balconies', 1);

        if(edit) {
            getPropertyDetails(
                edit,
                (data) => {
                    console.log('data', loginUserId.current, data.account.accountId);   
                    if(loginUserId.current == data.account.accountId) {
                        console.log('>>edit<<')
                        propertyId.current = edit;

                        setPropertySaleType(data.sale_type);
                        setPropertyType(data.type);
                        setPropertyTypeName(data.type_name);
                        setAddedPhotos(data.photos);

                        setFurnishedStatus(data.furnishing);
                        setPropertyAge(data.property_age);
                        setCurrencyValue(data.currency);

                        setFloorAt(data.floor_no);
                        setFloorTotal(data.total_floors);

                        setAvailableDate(data.available_date);

                        setContactAgent(data.contact_agent);
                        
                        console.log('negotiable', data.negotiable, 'electricity', data.electricity, 'water', data.water)

                        setPriceNegotiable(data.negotiable);
                        setIncludeElectricity(data.electricity);
                        setIncludeWater(data.water);

                        setRentFamilty(data.rent_family);
                        setRentSingleMen(data.rent_men);
                        setRentSingleWoman(data.rent_women);
                        setValue('country', data.country);
                        setValue('city', data.city);
                        setValue('landmark', data.landmark);
                        setValue('locality', data.locality);
                        setValue('floor', data.floor);
                        setValue('address', data.address);
                        setValue('rent_price', data.cost);
                        setValue('sale_price', data.cost);
                        setValue('maintanance', data.maintenance);
                        setValue('description', data.description);
                        setValue('bedrooms', data.bedrooms);
                        setValue('bathrooms', data.bathrooms);
                        setValue('balconies', data.balconies);
                        
                    }
                },
                (error) => {
                    setErrorMessage(error)
                }
            )
        }

    }, [])

    React.useEffect(() => {
        if(selectedPlace) {
            const address = getAddressFromPlace(selectedPlace); 
            setValue('city', address.city);
            let area = '';
            if(address.area) {
                area = address.area;
            }else {
                if(address.address) {
                    const add = removeHtmlTags(address.address)
                    area = add.substring(add.indexOf("a")) ;
                }
            }
            setValue('area', area);
        }
    }, [selectedPlace])

    React.useEffect(() => {
        if(activeStep === 3 ) {
            saveDraft()
        }
    }, [activeStep])
    

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const changePropertyType = (type) => {
        setPropertyType(type);
        setPropertyTypeName(null);
    }

    const onLocationSelect = (address, location) => {
        setOpenLocationPicker(false);
        console.log('onLocationSelect', address, location)
    }

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const GetPropertySaleType = () => {
        if(propertySeleType == PROPERTY_SALE) {
            return (
                <Stack direction='row' spacing={2} sx={{p:2}}>
                    <Button key='btn-sele' variant='solid' >Sele</Button>
                    <Button key='btn-rent'  variant='outlined' onClick={() => setPropertySaleType(PROPERTY_RENT)}>Lease / Rent</Button>
                </Stack>
            )
        }else if(propertySeleType == PROPERTY_RENT) {
            return (
                <Stack direction='row' spacing={2} sx={{p:2}}>
                    <Button key='btn-sele' variant='outlined' onClick={() => setPropertySaleType(PROPERTY_SALE)}>Sale</Button>
                    <Button key='btn-rent' variant='solid'  >Lease / Rent</Button>
                </Stack>
            )
        }
        return (
            <Stack direction='row' spacing={2} sx={{p:2}}>
                <Button key='btn-sele' variant='outlined' onClick={() => setPropertySaleType(PROPERTY_SALE)}>Sale</Button>
                <Button key='btn-rent' variant='outlined' onClick={() => setPropertySaleType(PROPERTY_RENT)}>Lease / Rent</Button>
            </Stack>
        )
    }

    const GetPropertyType = () => {
        if(propertyType == TYPE_RESIDENTAL) {
            return (
                <Stack direction='row' spacing={2}  sx={{p:2}}>
                    <Button key='btn-residental' variant='solid'>Residental</Button>
                    <Button key='btn-commercial' variant='outlined' onClick={() => changePropertyType(TYPE_COMMERCIAL)}>Commercial</Button>
                </Stack>
            )            
        }else if(propertyType == TYPE_COMMERCIAL) {
            return (
                <Stack direction='row' spacing={2}  sx={{p:2}}>
                    <Button key='btn-residental' variant='outlined' onClick={() => changePropertyType(TYPE_RESIDENTAL)}>Residental</Button>
                    <Button key='btn-commercial' variant='solid' >Commercial</Button>
                </Stack>
            )
        }else {
            return (
                <Stack direction='row' spacing={2}  sx={{p:2}}>
                    <Button key='btn-residental' variant='outlined' onClick={() => changePropertyType(TYPE_RESIDENTAL)}>Residental</Button>
                    <Button key='btn-commercial' variant='outlined' onClick={() => changePropertyType(TYPE_COMMERCIAL)}>Commercial</Button>
                </Stack>
            )
        }
    }

    const GetPropertyTypeName = () => {
        if(propertyType == TYPE_RESIDENTAL) {
            return (
                <GetResidentalProperties selected={propertyTypeName} setSelected={setPropertyTypeName}/>
            )            
        }else if(propertyType == TYPE_COMMERCIAL) {
            return (
                <GetCommercialProperties selected={propertyTypeName} setSelected={setPropertyTypeName}/>
            )
        }else {
            return null;
        }
    }

    const GetLocationInfo = () => {

        return (
            <Stack direction='column' spacing={2} sx={{width: '30vw'}}>
                <Typography sx={{ml:2}} variant='h5'>{selectedPlace?.name}, {removeHtmlTags(selectedPlace?.adr_address)}</Typography>
                <Grid container spacing={2} sx={{width: '40vw'}}>
                    <Grid item  sm={12} md={6}>
                        <TextField
                            id="text-currency"
                            select
                            label="Country"
                            defaultValue="Singapore"
                            helperText="Please select your country"
                            {...register("country", { required: "Enter country name" })}
                            fullWidth
                            >
                            {countries.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                {item.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item  sm={12} md={6}>
                        <TextField
                            id="text-city"
                            select
                            label="City"
                            defaultValue="Singapore"
                            helperText="Please select your city"
                            {...register("city", { required: "Enter city name" })}
                            fullWidth
                            >
                            {cities.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                {item.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>                    
                    <Grid item sm={12} md={12}>
                        <TextField id="text-area" label="Address" variant="outlined" type='text'
                            {...register("address", { required: "Enter address" })}  fullWidth/>
                    </Grid>
                    <Grid item  sm={12} md={6}>
                        <TextField id="text-landmark" label="Landmark" variant="outlined" type='text'
                            {...register("landmark", { required: "Enter landmark" })} fullWidth/>     
                    </Grid>
                    <Grid item  sm={12} md={6}>
                        <TextField id="text-floor" label="House/Floor No (Optional)" variant="outlined" type='text'
                            {...register("floor")} fullWidth/>
                    </Grid>
                </Grid>
            </Stack>
        )

    }


    const GetPropertyAge = () => {
        switch(propertyAge){
            case AGE_0_1:
                return(
                    <Stack direction='row' spacing={2}>
                        <Button key='btn-01yr' variant='solid'  startDecorator={<CheckIcon />}>0-1 Year</Button>
                        <Button key='btn-25yr' variant='outlined' onClick={() => setPropertyAge(AGE_2_5)}>2-5 Years</Button>
                        <Button key='btn-510yr' variant='outlined' onClick={() => setPropertyAge(AGE_5_10)}>5-10 Years</Button>
                        <Button key='btn-10pyr' variant='outlined' onClick={() => setPropertyAge(AGE_10p)}>10+ Years</Button>
                    </Stack>
                )
            case AGE_2_5:
                return(
                    <Stack direction='row' spacing={2}>
                        <Button key='btn-01yr' variant='outlined' onClick={() => setPropertyAge(AGE_0_1)}>0-1 Year</Button>
                        <Button key='btn-25yr' variant='solid'  startDecorator={<CheckIcon />}>2-5 Years</Button>
                        <Button key='btn-510yr' variant='outlined' onClick={() => setPropertyAge(AGE_5_10)}>5-10 Years</Button>
                        <Button key='btn-10pyr' variant='outlined' onClick={() => setPropertyAge(AGE_10p)}>10+ Years</Button>
                    </Stack>
                )
            case AGE_5_10:
                return(
                    <Stack direction='row' spacing={2}>
                        <Button key='btn-01yr' variant='outlined' onClick={() => setPropertyAge(AGE_0_1)}>0-1 Year</Button>
                        <Button key='btn-25yr' variant='outlined' onClick={() => setPropertyAge(AGE_2_5)}>2-5 Years</Button>
                        <Button key='btn-510yr' variant='solid'  startDecorator={<CheckIcon />}>5-10 Years</Button>
                        <Button key='btn-10pyr' variant='outlined' onClick={() => setPropertyAge(AGE_10p)}>10+ Years</Button>
                    </Stack>
                )
            case AGE_10p:
                return(
                    <Stack direction='row' spacing={2}>
                        <Button key='btn-01yr' variant='outlined' onClick={() => setPropertyAge(AGE_0_1)}>0-1 Year</Button>
                        <Button key='btn-25yr' variant='outlined' onClick={() => setPropertyAge(AGE_2_5)}>2-5 Years</Button>
                        <Button key='btn-510yr' variant='outlined' onClick={() => setPropertyAge(AGE_5_10)}>5-10 Years</Button>
                        <Button key='btn-10pyr' variant='solid'  startDecorator={<CheckIcon />}>10+ Years</Button>
                    </Stack>
                )
            default:
                return(
                    <Stack direction='row' spacing={2}>
                        <Button key='btn-01yr' variant='outlined' onClick={() => setPropertyAge(AGE_0_1)}>0-1 Year</Button>
                        <Button key='btn-25yr' variant='outlined' onClick={() => setPropertyAge(AGE_2_5)}>2-5 Years</Button>
                        <Button key='btn-510yr' variant='outlined' onClick={() => setPropertyAge(AGE_5_10)}>5-10 Years</Button>
                        <Button key='btn-10pyr' variant='outlined' onClick={() => setPropertyAge(AGE_10p)}>10+ Years</Button>
                    </Stack>
                )                                                                
        }
    }


    const GetRentOutOptions = () => {

        const list = [];

        if(rentFamilty) {
            list.push(<Button key='btn-family' variant='solid' startDecorator={<CheckIcon />} onClick={() => setRentFamilty(!rentFamilty)}>Family</Button>)
        }else {
            list.push(<Button key='btn-family' variant='outlined' startDecorator={<AddIcon />} onClick={() => setRentFamilty(!rentFamilty)}>Family</Button>)
        }

        if(rentSingleMen) {
            list.push(<Button key='btn-men' variant='solid' startDecorator={<CheckIcon />} onClick={() => setRentSingleMen(!rentSingleMen)}>Single Men</Button>)
        }else {
            list.push(<Button key='btn-men' variant='outlined' startDecorator={<AddIcon />} onClick={() => setRentSingleMen(!rentSingleMen)}>Single Men</Button>)
        }

        if(rentSingleWoman) {
            list.push(<Button key='btn-women' variant='solid' startDecorator={<CheckIcon />} onClick={() => setRentSingleWoman(!rentSingleWoman)}>Single Woman</Button>)
        }else {
            list.push(<Button key='btn-women' variant='outlined' startDecorator={<AddIcon />} onClick={() => setRentSingleWoman(!rentSingleWoman)}>Single Woman</Button>)
        }

        return (
            <Stack direction='row' spacing={2}>
                {list}
            </Stack>
        )
    }

    const GetAgentOptions = () => {
        if(contactAgent) {
            return (
                <Stack direction='row' spacing={2}>
                    <Button key='btn-yes' variant='solid' startDecorator={<CheckIcon />}>Yes</Button>
                    <Button key='btn-no' variant='outlined' onClick={() => setContactAgent(false)}>No</Button>
                </Stack>
            )
        }
        return (
            <Stack direction='row' spacing={2}>
                <Button key='btn-yes' variant='outlined' onClick={() => setContactAgent(true)} >Yes</Button>
                <Button key='btn-no' variant='solid' startDecorator={<CheckIcon />} >No</Button>
            </Stack>
        )        
    }

    const GetFurnishedStatus = () => {
        switch(furnishedStatus) {
            case FURNISHED:
                return (
                    <Stack direction='row' spacing={2}>
                        <Button key='btn-fn' variant='solid' startDecorator={<CheckIcon />}>Furnished</Button>
                        <Button key='btn-sf' variant='outlined' onClick={() => setFurnishedStatus(SEMI_FURNISHED)}>Semi Furnished</Button>
                        <Button key='btn-uf' variant='outlined' onClick={() => setFurnishedStatus(UN_FURNISHED)}>Un Furnished</Button>
                    </Stack>
                )
            case SEMI_FURNISHED:
                return (
                    <Stack direction='row' spacing={2}>
                        <Button key='btn-fn' variant='outlined' onClick={() => setFurnishedStatus(FURNISHED)}>Furnished</Button>
                        <Button key='btn-sf' variant='solid' startDecorator={<CheckIcon />}>Semi Furnished</Button>
                        <Button key='btn-uf' variant='outlined' onClick={() => setFurnishedStatus(UN_FURNISHED)}>Un Furnished</Button>
                    </Stack>
                )
            case UN_FURNISHED:
                return (
                    <Stack direction='row' spacing={2}>
                        <Button key='btn-fn' variant='outlined' onClick={() => setFurnishedStatus(FURNISHED)}>Furnished</Button>
                        <Button key='btn-sf' variant='outlined' onClick={() => setFurnishedStatus(SEMI_FURNISHED)}>Semi Furnished</Button>
                        <Button key='btn-uf' variant='solid' startDecorator={<CheckIcon />}>Un Furnished</Button>
                    </Stack>
                )
            default:
                return (
                    <Stack direction='row' spacing={2}>
                        <Button key='btn-fn' variant='outlined' onClick={() => setFurnishedStatus(FURNISHED)}>Furnished</Button>
                        <Button key='btn-sf' variant='outlined' onClick={() => setFurnishedStatus(SEMI_FURNISHED)}>Semi Furnished</Button>
                        <Button key='btn-uf' variant='outlined' onClick={() => setFurnishedStatus(UN_FURNISHED)}>Un Furnished</Button>
                    </Stack>
                )                              
        }
    }


    const GetMoreDetails = () => {
        if(propertyType == TYPE_RESIDENTAL) {
           return (
                <Box>
                    <Typography variant='h4'>Tell us more about your property.</Typography>    
                    <Stack direction='column' spacing={3} sx={{mt: 2}}>
                        <Box sx={{ width: 300 }}>
                            <Typography variant='subtitle2'>No. of Bedrooms</Typography>
                            <TextField
                                    id="input-bedrooms"
                                    type='number'
                                    sx={{ width: 300 }}
                                    {...register("bedrooms", { required: "Enter bedrooms" })}
                                    />
                        </Box>
                        <Box sx={{ width: 300 }}>
                            <Typography variant='subtitle2'>No. of Bathrooms</Typography>
                            <TextField
                                    id="input-bathrooms"
                                    type='number'
                                    sx={{ width: 300 }}
                                    {...register("bathrooms", { required: "Enter bathrooms" })}
                                    />
                        </Box>     
                        <Box sx={{ width: 300 }}>
                            <Typography variant='subtitle2'>No. of Balconies </Typography>
                            <TextField
                                    id="input-balconies"
                                    type='number'
                                    sx={{ width: 300 }}
                                    {...register("balconies", { required: "Enter balconies" })}
                                    />
                        </Box>  
                        <Box>
                            <Typography variant='subtitle2'>Property Floors</Typography>
                            <Stack direction='row' spacing={2} sx={{mt:2}}>

                                <Autocomplete
                                    disablePortal
                                    id="total-floors"
                                    options={floorCount.current}
                                    sx={{ width: 300 }}
                                    value={floorAt}
                                    onChange={(event, value) => setFloorAt(value)}
                                    renderInput={(params) => <TextField {...params} label="Property at Floor" {...register("floor_at")}  />}
                                    getOptionLabel={(option) =>typeof(option) =='number'? String (option) :option}                                    
                                    />

                                <Autocomplete
                                    disablePortal
                                    id="total-floors"
                                    options={totalFloorCount.current}
                                    sx={{ width: 300 }}
                                    value={floorTotal}
                                    onChange={(event, value) => setFloorTotal(value)}
                                    renderInput={(params) => <TextField {...params} label="Total Floors" {...register("total_floors")} />}
                                    getOptionLabel={(option) =>typeof(option) =='number'? String (option) :option}                                                                        
                                    />                                    
                            </Stack>                          
                        </Box>
                        <Box >
                                <Typography variant='subtitle2' sx={{mb:2}}>Age of property</Typography>
                                <GetPropertyAge />
                        </Box>                           
                        <Box >
                                <Typography variant='subtitle2'  sx={{mb:2}}>Available Date</Typography>
                                <TextField
                                    id="available-date"
                                    type='date'
                                    value={availableDate}
                                    onChange={(e) => setAvailableDate(e.target.value)}
                                    variant="outlined"
                                    sx={{ width: 300 }}
                                />
                            </Box>      
                        <Box >
                                <Typography  variant='subtitle2'  sx={{mb:2}}>Furniture Status</Typography>
                                <GetFurnishedStatus />
                        </Box>                                                    
                        <Box >
                                <Typography  variant='subtitle2'  sx={{mb:2}}>Willing to rent out to?</Typography>
                                <GetRentOutOptions />
                        </Box>   
                        <Box >
                                <Typography  variant='subtitle2'  sx={{mb:2}}>Are you ok if Agent contacted you?</Typography>
                                <GetAgentOptions />
                        </Box>                                                                       
                    </Stack>
                </Box>
           )

        }else if(propertyType == TYPE_COMMERCIAL) {
            return (
                <Box>
                    <Typography variant='h4'>Tell us more about your property.</Typography>    
                    <Stack direction='column' spacing={3} sx={{mt: 2}}>
                        <Box sx={{ width: 300 }}>
                            <Typography variant='subtitle2'>No. of Rooms</Typography>
                            <TextField
                                    id="input-bedrooms"
                                    type='number'
                                    sx={{ width: 300 }}
                                    {...register("bedrooms", { required: "Enter bedrooms" })}
                                    />
                        </Box>
                        <Box sx={{ width: 300 }}>
                            <Typography variant='subtitle2'>No. of Bathrooms</Typography>
                            <TextField
                                    id="input-bathrooms"
                                    type='number'
                                    sx={{ width: 300 }}
                                    {...register("bathrooms", { required: "Enter bathrooms" })}
                                    />
                        </Box>     
                        <Box>
                            <Typography variant='subtitle2'>Property Floors</Typography>
                            <Stack direction='row' spacing={2} sx={{mt:2}}>

                                <Autocomplete
                                    disablePortal
                                    id="total-floors"
                                    options={floorCount.current}
                                    sx={{ width: 300 }}
                                    value={floorAt}
                                    onChange={(event, value) => setFloorAt(value)}
                                    renderInput={(params) => <TextField {...params} label="Property at Floor" {...register("floor_at")}  />}
                                    getOptionLabel={(option) =>typeof(option) =='number'? String (option) :option}                                    
                                    />

                                <Autocomplete
                                    disablePortal
                                    id="total-floors"
                                    options={totalFloorCount.current}
                                    sx={{ width: 300 }}
                                    value={floorTotal}
                                    onChange={(event, value) => setFloorTotal(value)}
                                    renderInput={(params) => <TextField {...params} label="Total Floors" {...register("total_floors")} />}
                                    getOptionLabel={(option) =>typeof(option) =='number'? String (option) :option}                                                                        
                                    />                                    
                            </Stack>                          
                        </Box>
                        <Box >
                                <Typography variant='subtitle2' sx={{mb:2}}>Age of property</Typography>
                                <GetPropertyAge />
                        </Box>                           
                        <Box >
                                <Typography variant='subtitle2'  sx={{mb:2}}>Available Date</Typography>
                                <TextField
                                    id="available-date"
                                    type='date'
                                    value={availableDate}
                                    onChange={(e) => setAvailableDate(e.target.value)}
                                    variant="outlined"
                                    sx={{ width: 300 }}
                                />
                            </Box>      
                        <Box >
                                <Typography  variant='subtitle2'  sx={{mb:2}}>Furniture Status</Typography>
                                <GetFurnishedStatus />
                        </Box>                                                    
                        <Box >
                                <Typography  variant='subtitle2'  sx={{mb:2}}>Willing to rent out to?</Typography>
                                <GetRentOutOptions />
                        </Box>   
                        <Box >
                                <Typography  variant='subtitle2'  sx={{mb:2}}>Are you ok if Agent contacted you?</Typography>
                                <GetAgentOptions />
                        </Box>                                                                       
                    </Stack>
                </Box>
           )
        }
    }

    const validateNext = () => {
        switch(activeStep) {
            case 0: 
                if(propertySeleType === null) {
                    setErrorMessage('Select property sale type!')
                    return;
                }
                if(propertyType === null) {
                    setErrorMessage('Select property kind!')
                    return;
                }
                if(propertyTypeName === null) {
                    setErrorMessage('Select property option!')
                    return;
                }
                handleNext();
            break;

            case 1: 
                const country = getValues('country');
                const city = getValues('city');
                const address = getValues('address');
                const landmark = getValues('landmark');
                const floor = getValues('floor');

                if(!country) {
                    setErrorMessage('Please select country!')
                    return;
                }
                if(!city) {
                    setErrorMessage('Please select city!')
                    return;
                }
                if(!address) {
                    setErrorMessage('Please enter your address!')
                    return;
                }
                if(!landmark) {
                    setErrorMessage('Please enter your landmark!')
                    return;
                }

                addressObj.current = {
                    country: country?.toLowerCase(), 
                    city: city?.toLowerCase(), 
                    address: address?.toLowerCase(), 
                    landmark: landmark?.toLowerCase(), 
                    floor: floor?.toLowerCase()
                }

                handleNext();                
            break;
            case 2:
                const bedrooms = getValues('bedrooms');
                if(!bedrooms) {
                    setErrorMessage('Please enter bedrooms!')
                    return;
                }
                const bathrooms = getValues('bathrooms');
                if(!bathrooms) {
                    setErrorMessage('Please enter bathrooms!')
                    return;
                } 
                if(!propertyAge) {
                    setErrorMessage('Please select property age!')
                    return;
                }
                if(!furnishedStatus) {
                    setErrorMessage('Please select furnished status!')
                    return;
                }                
                handleNext();                
            break;

            case 3: 
                uploadSelectedPhotos();                
            break;

            case 4:
                savePricing(); 
            break;

            case 5: 
                submitForReview();
            break;                                                            

            case 6: 
                handleNext();
            break;            

        }
    }

    const saveDraft = () => {

        // const address = getAddressFromPlace(selectedPlace);
        // address: {
        //     name: address.name,
        //     area: address.area,
        //     address: address.address,
        //     city: address.city,
        //     country: address.country,
        //     place_id: address.place_id,
        //     url: address.url,
        //     locality: getValues('locality'),
        //     floor: getValues('floor'),
        // }
        const address = addressObj.current;

        const property = {
            id: propertyId.current,
            sale_type: propertySeleType,
            type: propertyType,
            type_name: propertyTypeName,
            address: address,
            place: JSON.stringify(selectedPlace),
            bedrooms: getValues('bedrooms'),
            bathrooms: getValues('bathrooms'),
            balconies: getValues('balconies'),
            furnishing: furnishedStatus,
            floor_at: floorAt,
            total_floors: floorTotal,
            property_age: propertyAge,
            available_date: availableDate,
            rent_family: rentFamilty,
            rent_men: rentSingleMen,
            rent_women: rentSingleWoman,
            contact_agent: contactAgent,
            lat: selectedPlace?.geometry?.location?.lat,
            lng: selectedPlace?.geometry?.location?.lng,
        }

        console.log('property', property);
        setSavingDraft(true)

        apiPostSecure(
                apiUrl.propertyDraft, 
                property,
                (response) => {
                    console.log('response', response);
                    propertyId.current = response.id;
                    console.log('propertyId', propertyId.current);
                    setTimeout(() => {setSavingDraft(false);}, 3000);
                },
                (error) => {
                    setErrorMessage(error)
                }
            )

    }

    const onFileSelected = (files) => {
        console.log('files >', files[0]);
        const list = []
        for(let i=0; i<files.length; i++) {
            list.push(files[i])
        }
        setPropertyPhotos(list);
    };

    const removeFileAt = (index) => {
        const array = [...propertyPhotos];
        const items = array.splice(index, 1);
        console.log('items', items);
        console.log('array', array);
        setPropertyPhotos(array);
    }

    const uploadSelectedPhotos = () => {

        if(propertyPhotos.length > 0) {

            const form = new FormData();
            form.append('id', propertyId.current);
            form.append('count', propertyPhotos.length);
    
            for(let i=0; i<propertyPhotos.length; i++) {
                form.append(`photo_${i}`, propertyPhotos[i])
            }
    
            setLoadingTitle('Please wait!')
            
            apiCallPostWithForm(
                apiUrl.propertyPhotos,
                form,
                (response) => {
                    console.log('response', response)
                    setLoadingTitle(null);
                    setAddedPhotos(response.photos);
                    setPropertyPhotos([]);
                    handleNext();
                },
                (error) => {
                    setLoadingTitle(null);
                    setErrorMessage(error);
                }
            )

            return
        }

        if(addedPhotos.length > 0) {
            handleNext();
            return
        }

        setErrorMessage('Please select photo to upload!')
       
    }

    const GetSelectedPropertyPhotos = () => {
        
        const list = [];
        console.log('propertyPhotos', propertyPhotos)
        propertyPhotos.forEach((file, i) => {
            list.push(
                <Grid item spacing={2}>
                   <Grid container justifyContent="flex-end">
                        <img src={file? URL.createObjectURL(file) : null} width={100} height={100} ></img>
                        <Button  variant="text" onClick={() => removeFileAt(i)}><CancelRoundedIcon color="primary" fontSize="small"  /></Button>
                   </Grid>
                </Grid>
            )
        })

        return (
            <Stack direction='column' spacing={2} sx={{m:3}}> 
                <Grid container>
                    {list}
                </Grid>
            </Stack>
        );
    }

    const removePhoto = (photo) => {

        const api = `${apiUrl.propertyPhotos}?property=${propertyId.current}&media=${photo.id}`

        apiCallSecureDeleteUrl(
            api,
            (response) => {
                setAddedPhotos(response.photos);
            },
            (error) => {
                setErrorMessage(error);
            }
        )
    }

    const GetSavedPropertyPhotos = () => {
        
        const list = [];
        console.log('addedPhotos', addedPhotos)
        addedPhotos.forEach((file, i) => {
            list.push(
                <Grid item spacing={2}>
                   <Grid container justifyContent="flex-end">
                        <img src={`${apiUrl.property}${file.media}`} width={100} height={100} ></img>
                        <Button  variant="text" onClick={() => removePhoto(file)}><CancelRoundedIcon color="primary" fontSize="small"  /></Button>
                   </Grid>
                </Grid>
            )
        })

        return (
            <Stack direction='column' spacing={2} sx={{m:3}}> 
                <Grid container spacing={2}>
                    {list}
                </Grid>
            </Stack>
        );
    }

    const GetUploadPhotoView = () => {
        console.log('loadingView', savingDraft)
        if(savingDraft) {
            return (
                <Box>
                    <Typography variant='subtitle'>Saving Draft, Please wait!</Typography>
                    <LinearProgress sx={{mt:1}} />
                </Box>
            )
        }

        return (
            <Stack direction='column' spacing={2} sx={{width: '50vw'}}>
                <Typography variant='h4' >Upload photos</Typography>
                <Box>
                    <Typography variant='subtitle' >Upload property photos</Typography>
                    <Box
                        sx={{mt: 2}}>
                        <FileUploader
                            multiple={true}
                            handleChange={onFileSelected}
                            name="file"
                            types={fileTypes}
                            maxSize={10}
                        />
                        <GetSelectedPropertyPhotos />
                        <GetSavedPropertyPhotos />
                    </Box>
                </Box>
            </Stack>
        )
    }

    const savePricing = () => {
        
        if(propertySeleType == PROPERTY_SALE) {

            const cost = getValues('sale_price')

            if(!cost) {
                setErrorMessage('Enter selling price of your property!')
                return
            }

            const description = getValues('description');
            const currency = getValues('currency');

            const data = {
                id: propertyId.current,
                cost: cost,
                currency: currency,
                priceNegotiable: priceNegotiable,
                description: description
            }

            setLoadingTitle('Saving property details!');

            apiPostSecure(
                apiUrl.propertyCost,
                data,
                (response) => {
                    console.log('response', response)
                    setLoadingTitle(null);
                    handleNext();
                },
                (error) => {
                    setLoadingTitle(null);
                    setErrorMessage(error)
                }
            )

        }else {
            const cost = getValues('rent_price')

            if(!cost) {
                setErrorMessage('Enter rent cost for your property!')
                return
            }

            const maintanance = getValues('maintanance');
            const description = getValues('description');
            const currency = getValues('currency');

            const data = {
                id: propertyId.current,
                cost: cost,
                currency: currencyValue,
                maintanance: maintanance,
                negotiable: priceNegotiable,
                electricity: includeElectricity,
                water: includeWater,
                description: description
            }

            setLoadingTitle('Saving property details!');

            apiPostSecure(
                apiUrl.propertyCost,
                data,
                (response) => {
                    setLoadingTitle(null);
                    handleNext();
                },
                (error) => {
                    setLoadingTitle(null);
                    setErrorMessage(error)
                }
            )

        }

    } 

    const submitForReview = () => {

        const data = {
            id: propertyId.current,
        }

        setLoadingTitle('Submitting for review!');

        apiPostSecure(
            apiUrl.propertyReview,
            data,
            (response) => {
                setLoadingTitle(null);
                navigate('/profile')
            },
            (error) => {
                setLoadingTitle(null);
                setErrorMessage(error)
            }
        )
    }


    const GetPricingTab = () => {
        if(propertySeleType == PROPERTY_SALE) {
           return (
                <Stack direction='column' spacing={2}>
                    <Box>
                        <Typography variant='subtitle2'>Select currency</Typography>
                        <TextField
                            id="standard-select-currency"
                            select
                            defaultValue="SGD"
                            variant="standard"
                            value={currencyValue}
                            onChange={(e) => setCurrencyValue(e.target.value)}
                            >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Typography sx={{mt:2}} variant='subtitle2'>Expected Sale Price</Typography>
                        <TextField
                                    id="text-price"
                                    type='number'
                                    variant="outlined"
                                    sx={{ width: 300 }}
                                    {...register("sale_price")} 
                                />
                    </Box>
                    <Box>
                        <FormControlLabel
                            label="Price Negotiable"
                            control={
                                <Checkbox
                                    checked={priceNegotiable}
                                    onChange={(event) => setPriceNegotiable(event.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                            }
                        />
                    </Box>
                    <Box direction='col'>
                        <Typography variant='subtitle2'>Property Description</Typography>
                        <Typography variant='caption' >Adding description will increase your listing visibility</Typography>
                        <br></br>
                        <TextField
                                    id="text-description"
                                    type='text'
                                    variant="outlined"
                                    sx={{ width: 300 }}
                                    multiline
                                    rows={4}
                                    {...register("description")} 
                                />
                    </Box>
                </Stack>
           )
        }else if(propertySeleType == PROPERTY_RENT) {
            return (
                <Stack direction='column' spacing={2}>
                    <Box>
                        <Typography variant='subtitle2'>Select currency</Typography>
                        <TextField
                            id="standard-select-currency"
                            select
                            defaultValue="SGD"
                            variant="standard"
                            value={currencyValue}
                            onChange={(e) => setCurrencyValue(e.target.value)}
                            >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>                  
                        <Typography sx={{mt:2}} variant='subtitle2'>Expected Rental Cost (Monthly)</Typography>
                        <TextField
                                    id="text-price"
                                    type='number'
                                    variant="outlined"
                                    sx={{ width: 300 }}
                                    {...register("rent_price")} 
                                />
                    </Box>
                    <Box>
                        <Typography variant='subtitle2'>Other Charges (ex. Maintanance)</Typography>
                        <TextField
                                    id="text-price"
                                    type='number'
                                    variant="outlined"
                                    sx={{ width: 300 }}
                                    {...register("maintanance")} 
                                />
                    </Box>                    
                    <Box>
                        <FormControlLabel
                            label="Price Negotiable"
                            control={
                                <Checkbox
                                    checked={priceNegotiable}
                                    onChange={(event) => setPriceNegotiable(event.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                            }
                        />                           
                        <FormControlLabel
                            label="Include Electricity Charge"
                            control={
                                <Checkbox
                                    checked={includeElectricity}
                                    onChange={(event) => setIncludeElectricity(event.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                            }
                        />
                       <FormControlLabel
                            label="Include Water Charge"
                            control={
                                <Checkbox
                                    checked={includeWater}
                                    onChange={(event) => setIncludeWater(event.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                            }
                        />                                             
                    </Box>
                    <Box direction='col'>
                        <Typography variant='subtitle2'>Property Description</Typography>
                        <Typography variant='caption' >Adding description will increase your listing visibility</Typography>
                        <br></br>
                        <TextField
                                    id="text-description"
                                    type='text'
                                    variant="outlined"
                                    sx={{ width: 300 }}
                                    multiline
                                    rows={4}
                                    {...register("description")} 
                                />
                    </Box>
                </Stack>
           )          
        }
    }

    const GetCurrentStep = ({activeStep}) => {

        switch(activeStep) {

            case 0:
                return (
                    <Box>
                        <Stack direction='column' spacing={2}>
                            <Box>
                                <Typography variant='h4'>Welcome, {getUserName()}</Typography>
                                <Typography variant='h6'>Fill out basic details</Typography>
                            </Box>
                            <Box>
                                <Typography variant='body' >I'm looking to</Typography>
                                <GetPropertySaleType />
                            </Box>
                            <Box>
                                <Typography variant='body' >What kind of property you have?</Typography>
                                <GetPropertyType />
                            </Box>
                            <GetPropertyTypeName />
                        </Stack>
                    </Box>
                )
            case 1:
                return (
                    <Stack direction='column' spacing={2} >
                        <Typography variant='h4' >Where is your property located?</Typography>
                        <GetLocationInfo /> 
                        {/* <Typography variant='h6'>--OR--</Typography>
                        <Button variant='outlined' onClick={() => setOpenLocationPicker(true)}>Select from Map</Button> */}
                    </Stack>
                )                
            case 2:
                return (
                    <GetMoreDetails />
                )
            case 3:
                return (
                    <GetUploadPhotoView /> 
                )
            case 4:
                return (
                    <GetPricingTab />
                )
            case 5:
                return (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        >
                            <Grid item xs={3}>
                                <Stack direction='column' sx={{width: '40vw'}} spacing={2} justifyContent="center" alignItems="center">
                                    <img src={TickIcon} width={200} />
                                    <Typography sx={{textAlign: "center"}} variant='h6'>Congratualtions! You have successfully provided all the details of your property.</Typography>
                                    <Typography sx={{textAlign: "center"}}  variant='h6'>Please click on submit button to send your property for review. Once we reviewed your property It will be available emidiatly in listings.</Typography>
                                    <Typography sx={{textAlign: "center"}}  variant='h6'>If you are not ready yet, your property will be saved in drafts so you can edit and submit anytime you want.</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                )
            default:
                return (
                    <Box>
                        <Typography variant='h6'>completed</Typography>
                    </Box>
                )                                                                                                

        }
            

    }

    return (
            <Box sx={{ width: '100%' }}>
                <Grid container spacing={4}>
                    <Grid item xs={3} >
                        <Card variant="outlined" sx={{ m:1, backgroundColor: '#fafafa'}}>
                            <Stepper activeStep={activeStep} orientation='vertical'>
                                {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = (
                                    <Typography variant="caption">Optional</Typography>
                                    );
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                                })}
                            </Stepper>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button key='btn-submit'  onClick={handleReset} variant='contained' color='success'>Submit</Button>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>                        
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                            key='btn-back'
                                            >
                                            Back
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {isStepOptional(activeStep) && (
                                        <Button  key='btn-skip' color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                            Skip
                                        </Button>
                                        )}

                                        <Button  key='btn-next' onClick={validateNext} >
                                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            )}                            
                        </Card>                        
                    </Grid>
                    <Grid item sx={9}>
                        <Box sx={{p:1}}>
                            <Typography variant='body' color='error'>{errorMessage}</Typography>
                            <GetCurrentStep activeStep={activeStep} />
                        </Box>
                    </Grid>
                </Grid>
                <ProgressDialog title={loadingDialog} />
                <LocationPickerModal open={openLocationPicker} setOpen={setOpenLocationPicker} onAddressSelect={onLocationSelect} />
            </Box>
        );
}
  
export default AddProperty;