
import * as React from 'react';
import Grid from '@mui/material/Box';
import PropertyCard from '../../components/PropertyCard';


const RecentTab = ({ properties, setShowLogin, onSuccess, onError }) => {

  const data = [];
  properties.forEach((item) => 
      data.push(
          <Grid item xs={12} md={6} lg={4}>
              <PropertyCard property={item} setShowLogin={setShowLogin} onSuccess={onSuccess} onError={onError}/>                    
          </Grid>
      )
  )
  return data

}

export default RecentTab;