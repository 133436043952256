import axios from "axios";
import { isAuthenticated, getAccessToken } from './session'
import { GoogleMapsAPI } from '../pages/client-config';

const baseUrl = 'https://property-api.mirobotic.tech/';
// const baseUrl = 'http://127.0.0.1:8000/';

const apiUrl = {
  baseUrl: `${baseUrl}`,
  account: `${baseUrl}accounts`,
  signIn: `${baseUrl}accounts/sign-in/`,
  signUp: `${baseUrl}accounts/sign-up/`,
  bus: `${baseUrl}settings/bus/`,
  deleteFile: `${baseUrl}settings/delete-file/`,
  birth: `${baseUrl}settings/berth/`,
  announceMedia: `${baseUrl}announce`,
  announcement: `${baseUrl}announce/announcement/`,
  greeting: `${baseUrl}announce/greeting/`,
  delay: `${baseUrl}announce/delay/`,
  schedule: `${baseUrl}announce/schedule/`,
  locations: `${baseUrl}temi/locations/`,
  notifyDelay: `${baseUrl}temi/notify-delay/`,
  notifyAnnounce: `${baseUrl}temi/notify-announce/`,
  forgotPassword: `${baseUrl}accounts/forgot-password/`,
  changePassword: `${baseUrl}accounts/change-password/`,
  resetPassword: `${baseUrl}accounts/reset-password/`,
  dashboard: `${baseUrl}accounts/dashboard/`,
  activate: `${baseUrl}accounts/activate/`,
  profile: `${baseUrl}accounts/profile/`,
  firmInfo: `${baseUrl}accounts/info/`,
  verifyActivateToken: `${baseUrl}accounts/verify/`,
  verifyResetToken: `${baseUrl}accounts/verify-reset/`,
  staff: `${baseUrl}accounts/staff/`,
  logo: `${baseUrl}accounts/logo/`,
  propertyDraft: `${baseUrl}property/draft/`,
  propertyPhotos: `${baseUrl}property/photos/`,
  propertyCost: `${baseUrl}property/cost/`,
  propertyReview: `${baseUrl}property/review/`,
  propertyList: `${baseUrl}property/list/`,
  bookmark: `${baseUrl}property/bookmark/`,
  recentproperties: `${baseUrl}property/recent/`,
  propertyStatus: `${baseUrl}property/status/`,
  homeProperties: `${baseUrl}property/home/`,
  searchProperties: `${baseUrl}property/search/`,
  propertyInfo: `${baseUrl}property/info/`,
  chatMessage: `${baseUrl}chat/message/`,
  chatContacts: `${baseUrl}chat/contacts/`,
  property: `${baseUrl}property`,
};

export { baseUrl, apiUrl };

export async function apiCallUnsecureGet(api, response, error) {
  console.log('apiCallSecureGet ', api);
  axios
    .get(api)
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });

}

export async function apiCallSecureGet(api, response, error) {

  console.log('apiCallSecureGet ', api);
  axios
    .get(api,
      {
        headers: {
          'Authorization': `Token ${getAccessToken()}`
        }
      }
    )
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });

}



export async function apiCallGetIgnoreLogin(api, response, error) {

  console.log('apiCallSecureGet ', api);
  const token = getAccessToken();

  if (token === null) {
    axios
      .get(api)
      .then((res) => {
        console.warn('result', res)
        const result = res.data;
        if (result.code === 200) {
          response(result)
        } else {
          error(result.msg)
        }
      })
      .catch((err) => {
        if (err.response) {
          error(err.response.data.msg)
        } else if (err.request) {
          console.log(err.request);
          error(err.request)
        } else {
          console.log('Error', err.message);
          error(`Error ${err.message}`)
        }
        console.log(err.config);
      });
    return
  }
  axios
    .get(api,
      {
        headers: {
          'Authorization': `Token ${getAccessToken()}`
        }
      }
    )
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });

}

export async function apiPostIgnoreLogin(api, data, response, onError) {



  const token = getAccessToken();

  console.log('apiPostIgnoreLogin ', token);

  if (token === null) {
    axios
      .post(
        api,
        data
      )
      .then((res) => {
        console.log('result', res.data)

        const result = res.data;

        if (result.code === 200) {
          response(result)
        } else {
          onError(result.msg)
        }

      })
      .catch((err) => {
        try {

          console.log('error', err.response)

          if (err.response) {
            onError(err.response.data.msg)
          } else if (err.request) {
            console.log(err.request);
            onError(err.request)
          } else {
            console.log('Error', err.message);
            onError(`Error ${err.message}`)
          }
        } catch (e) {
          console.log(e);
          onError('Something went wrong!')
        }
        console.log(err.config);
      });
    return;
  }
  axios
    .post(
      api,
      data,
      {
        headers: {
          'Authorization': `Token ${getAccessToken()}`
        }
      }
    )
    .then((res) => {
      console.log('result', res.data)

      const result = res.data;

      if (result.code === 200) {
        response(result)
      } else {
        onError(result.msg)
      }

    })
    .catch((err) => {
      try {

        console.log('error', err.response)

        if (err.response) {
          onError(err.response.data.msg)
        } else if (err.request) {
          console.log(err.request);
          onError(err.request)
        } else {
          console.log('Error', err.message);
          onError(`Error ${err.message}`)
        }
      } catch (e) {
        console.log(e);
        onError('Something went wrong!')
      }
      console.log(err.config);
    });
}

export async function apiCallPostWithForm(api, form, response, error) {

  console.log('uploadFile ', api);

  axios.post(api, form, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': `Token ${getAccessToken()}`
    },
  })
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });
};

export async function apiCallPutWithForm(api, form, response, error) {

  console.log('uploadFile ', api);

  axios.put(api, form, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': `Token ${getAccessToken()}`
    },
  })
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log('Error', err.response);
    });
};

export async function uploadFile(api, name, file, response, error) {

  console.log('uploadFile ', api);
  console.log(name, file.name);

  const formData = new FormData();
  formData.append(name, file);
  axios.post(api, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': `Token ${getAccessToken()}`
    },
  })
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });
};

export async function apiCallPostFormData(api, formData, response, error) {

  console.log('apiCallPostFormData ', api);

  axios.post(api, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': `Token ${getAccessToken()}`
    },
  })
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });
};

export async function apiCallSecureDelete(api, id, response, error) {

  const url = `${api}?id=${id}`;
  console.log('apiCallSecureDelete ', url);
  axios
    .delete(url,
      {
        headers: {
          'Authorization': `Token ${getAccessToken()}`
        }
      }
    )
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });

}


export async function apiCallSecureDeleteUrl(api, response, error) {

  axios
    .delete(api,
      {
        headers: {
          'Authorization': `Token ${getAccessToken()}`
        }
      }
    )
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });

}

export async function apiPostUnsecure(api, data, response, error) {

  console.log('apiPostUnsecure ', api, 'data ', data);

  axios
    .post(api, data)
    .then((res) => {
      console.warn('result', res)

      const result = res.data;

      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }

    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });
}


export async function apiPostSecure(api, data, response, onError) {

  console.log('apiPostSecure ', api, 'data ', data);

  axios
    .post(
      api,
      data,
      {
        headers: {
          'Authorization': `Token ${getAccessToken()}`
        }
      }
    )
    .then((res) => {
      console.log('result', res.data)

      const result = res.data;

      if (result.code === 200) {
        response(result)
      } else {
        onError(result.msg)
      }

    })
    .catch((err) => {
      try {

        console.log('error', err.response)

        if (err.response) {
          onError(err.response.data.msg)
        } else if (err.request) {
          console.log(err.request);
          onError(err.request)
        } else {
          console.log('Error', err.message);
          onError(`Error ${err.message}`)
        }
      } catch (e) {
        console.log(e);
        onError('Something went wrong!')
      }
      console.log(err.config);
    });
}

export async function apiPutSecure(api, data, response, error) {

  console.log('apiPutSecure ', api, 'data ', data);

  axios
    .put(
      api,
      data,
      {
        headers: {
          'Authorization': `Token ${getAccessToken()}`
        }
      }
    )
    .then((res) => {
      console.warn('result', res)

      const result = res.data;

      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }

    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });
}


export const searchPlaceCountry = (text, onSuccess, onError) => {
  //location=1.295933,103.786796&radius=50000
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${text}&key=${GoogleMapsAPI}&country=sg`,
    headers: {}
  };

  axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      onSuccess(response.data);
    })
    .catch((err) => {
      if (err.response) {
        onError(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        onError(err.request)
      } else {
        console.log('Error', err.message);
        onError(`Error ${err.message}`)
      }
      console.log(err.config);
    });
}

export default apiCallUnsecureGet;