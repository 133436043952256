import * as React from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';

import { useForm, Controller } from "react-hook-form";


import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, Box } from '@mui/system';
import Modal from '@mui/base/Modal';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';


import { logout, saveUser } from "../../../utils/session";
import { apiUrl, apiPostUnsecure, uploadFile } from "../../../utils/api";
import { showError, showSucess } from '../../../utils/toast';

const SignInModal = ({open, setOpen, onSuccess, onError}) => {

    const [isLoading, setLoading] = React.useState(false);

    const {
      register,
      handleSubmit,
      watch,
      setValue,
      formState: { errors },
    } = useForm();
    
    const [error, setError] = React.useState('')

    const navigate = useNavigate();  

    React.useEffect(() => {
        if(open) {
            setValue('role', 'U');
        }
    }, [open])

    async function sginUp(data) {
        console.log('sginUp', data);
        setLoading(true);
        apiPostUnsecure(
          apiUrl.signIn,
          data,
          (response) => {
            setLoading(false);
            setValue('email', null);
            setValue('password', null);
            saveUser(response);
            setOpen(false);
            onSuccess('Login success!');
          },
          (errorMsg) => {
            setLoading(false);
            console.log('error', errorMsg)
            setError(errorMsg);
          }
        );
      }

  return (
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>            
        </Box>   
        <form id="hook-form" onSubmit={handleSubmit(sginUp)}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        {...register("email", { required: "Enter email" })}
                        />
                    </Grid>                    
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        {...register("password", { required: "Enter password" })}
                        />
                    </Grid>
                </Grid>
                <input type="hidden" {...register(`role`)} />
                <LoadingButton fullWidth size="large"  form="hook-form" type="submit" variant="contained" loading={isLoading} sx={{ mt: 3, mb: 2 }}>
                    Login
                </LoadingButton>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Typography variant='body' color='error'>{error}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </form>
        </Box>
      </StyledModal>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};


const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: 400,
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});

export default SignInModal;