import * as React from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, Controller  } from "react-hook-form";

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Card  from '@mui/material/Card';
import { Stack, alpha, TextField, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { propertyTypeNames } from '../../services/PropertyService';
import { commercialOptions, residentalOptions } from '../../property/components/PropertyTypes';
import { useEffect } from 'react';
import {getAddressFromPlace} from '../../property/components/PlaceAutoComplete';

const autoOptions = {
    componentRestrictions: { country: "sg" },
    fields: ['address_components', 'geometry', 'icon', 'name', 'adr_address', 'geometry.location', 'place_id', 'url'],
    types: ["establishment"]
};

const GetPropertyTypeMenu = ({type, anchorTypeName, openTypeName, handleTypeNameClose}) => {
    if(propertyTypeNames.any === type) {
        return(
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorTypeName}
                open={openTypeName}
                onClose={() => handleTypeNameClose(null)}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
            >

                <MenuItem onClick={() => handleTypeNameClose(propertyTypeNames.residental)}>
                    <Typography variant='subtitle'>Residental</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleTypeNameClose(propertyTypeNames.commercial)}>
                    <Typography variant='subtitle'>Commercial</Typography>
                </MenuItem>
            </Menu>
        )
    }

    if(propertyTypeNames.residental === type) {
        return(
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorTypeName}
                open={openTypeName}
                onClose={() => handleTypeNameClose(null)}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
            >
                {/* <MenuItem  onClick={() => handleTypeNameClose(propertyTypeNames.any)}>
                    <Typography variant='subtitle'>All</Typography>
                </MenuItem> */}
                <MenuItem onClick={() => handleTypeNameClose(propertyTypeNames.residental)}>
                    <Typography variant='subtitle' color='primary'>Residental</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleTypeNameClose(propertyTypeNames.commercial)}>
                    <Typography variant='subtitle'>Commercial</Typography>
                </MenuItem>
            </Menu>
        )
    }

    return(
        <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorTypeName}
            open={openTypeName}
            onClose={() => handleTypeNameClose(null)}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
        >
            <MenuItem onClick={() => handleTypeNameClose(propertyTypeNames.residental)}>
                <Typography variant='subtitle' >Residental</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleTypeNameClose(propertyTypeNames.commercial)}>
                <Typography variant='subtitle' color='primary'>Commercial</Typography>
            </MenuItem>
        </Menu>
    )
}

export default function SearchBarCompact({onSearch, state}) {

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        control,
        onChange,
        formState: { errors },
      } = useForm();


    const autoCompleteRef = React.useRef();
    const inputRef = React.useRef();
    const address = React.useRef();
    
    const [sendQuery, setSendQuery] = React.useState(false);

    const [propertyType, setPropertyType] = React.useState(propertyTypeNames.residental)

    const [anchorType, setAnchorType] = React.useState(null);
    const openType = Boolean(anchorType);
    const handleTypeClick = (event) => {
        setAnchorType(event.currentTarget);
    };
    const handleTypeClose = () => {
        setAnchorType(null);
    };

    const [anchorTypeName, setAnchorTypeName] = React.useState(null);

    const openTypeName = Boolean(anchorTypeName);
    const handleTypeNameClick = (event) => {
        setAnchorTypeName(event.currentTarget);
    };
    const handleTypeNameClose = (type) => {
        setAnchorTypeName(null);
        if(type !== null) {
            setPropertyType(type)
        }
    };


    const [anchorPrice, setAnchorPrice] = React.useState(null);
    const openPrice = Boolean(anchorPrice);
    const handlePriceClick = (event) => {
        setAnchorPrice(event.currentTarget);
    };
    const handlePriceClose = (min, max) => {
        setAnchorPrice(null);
        if(max > 0) {
            setValue('min_value', min);
            setValue('max_value', max);
            return
        }
        setValue('min_value', null);
        setValue('max_value', null);
    };

    const [anchorBeds, setAnchorBeds] = React.useState(null);
    const openBeds = Boolean(anchorBeds);
    const handleBedsClick = (event) => {
        setAnchorBeds(event.currentTarget);
    };
    const handleBedsClose = () => {
        setAnchorBeds(null);
    };

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    const [stateProperty, setStateProperty] = React.useState(
        {
            c1: true,
            c2: false,
            c3: false,
            c4: false,
            c5: false,
            c6: false,
            c7: false,
            r1: true,
            r2: false,
            r3: false,
            r4: false,
            r5: false,
            r6: false,
            r7: false,
        }
    );

    const { c1, c2, c3, c4, c5, c6, c7, r1, r2, r3, r4, r5, r6, r7 } = stateProperty;

    const handlePropertyOptionChange = (event) => {
        setStateProperty({
            ...stateProperty,
            [event.target.name]: event.target.checked,
        });
    }

    const [stateBedsCount, setStateBedsCount] = React.useState({
        bedsAll: true,
        beds1: false,
        beds2: false,
        beds3: false,
        beds4: false,
        beds5p: false,
      });

    const { bedsAll, beds1, beds2, beds3, beds4, beds5p } = stateBedsCount;

    useEffect(() => {
        console.log('set state', state);
        if(state) {
            setValue('min_value', state.min_value);
            setValue('max_value', state.max_value);
            setValue('query', state.query);
            setPropertyType(state.type);
            setStateProperty(state.typeNames);
            setStateBedsCount(state.bedrooms);
            setTabValue(state.tabValue);
            setSendQuery(true);
        }

        // autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, autoOptions);

    }, [])

    useEffect(() => {
        if(!sendQuery) {
            return
        }
        setSendQuery(false);
        searchProperties();
    }, [sendQuery])

    const onSelectPlace = (e) => {
        const place = autoCompleteRef.current.getPlace();
        if(place == null) {
            return
        }
        console.log('query', e.target.value);
        address.current = getAddressFromPlace(place); 
        console.log('address', address.current);
    }

    const handleBedsOptionChange = (event) => {
        setStateBedsCount({
            ...stateBedsCount,
            [event.target.name]: event.target.checked,
        });
    }

    const searchProperties = () => {
        const type_names = [];
        if(propertyType === propertyTypeNames.commercial) {
            if(c1) {
                type_names.push(commercialOptions[0])
            }
            if(c2) {
                type_names.push(commercialOptions[1])
            }
            if(c3) {
                type_names.push(commercialOptions[2])
            }
            if(c4) {
                type_names.push(commercialOptions[3])
            }
            if(c5) {
                type_names.push(commercialOptions[4])
            }                        
            if(c6) {
                type_names.push(commercialOptions[5])
            }
            if(c7) {
                type_names.push(commercialOptions[6])
            }
        }else {
            if(r1) {
                type_names.push(residentalOptions[0])
            }
            if(r2) {
                type_names.push(residentalOptions[1])
            }
            if(r3) {
                type_names.push(residentalOptions[2])
            }
            if(r4) {
                type_names.push(residentalOptions[3])
            }
            if(r5) {
                type_names.push(residentalOptions[4])
            }                        
            if(r6) {
                type_names.push(residentalOptions[5])
            }
            if(r7) {
                type_names.push(residentalOptions[6])
            }   
        }
        
        const bedrooms = []
        if(stateBedsCount.beds1) {
            bedrooms.push(1);
        }
        if(stateBedsCount.beds2) {
            bedrooms.push(2);
        }
        if(stateBedsCount.beds3) {
            bedrooms.push(3);
        }
        if(stateBedsCount.beds4) {
            bedrooms.push(4);
        }
        if(stateBedsCount.beds5p) {
            for(let i=5; i<11; i++) {
                bedrooms.push(i);
            }
        }   
        
        let sale_type = '';
        if(tabValue==0) {
            sale_type = 'Sale';
        }else {
            sale_type = 'Rent';
        }

        const query = {
            type: propertyType,
            kinds: type_names,
            min_value: getValues('min_value'),
            max_value: getValues('max_value'),
            query: getValues('query'),
            bedroom_counts: bedrooms,
            sale_type: sale_type,
            address: address.current,
        }
        console.log('query', query);
        // navigate('search', query);
        onSearch(query);
    }

    const GetPropertyKinds = () => {
    
        const menu = [];
       
        if(propertyType === propertyTypeNames.residental) {
            residentalOptions.forEach((item, i) => {
                switch(i) {
                    case 0:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={r1} onChange={handlePropertyOptionChange} name='r1' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 1:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={r2} onChange={handlePropertyOptionChange} name='r2' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 2:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={r3} onChange={handlePropertyOptionChange} name='r3' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 3:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={r4} onChange={handlePropertyOptionChange} name='r4' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 4:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={r5} onChange={handlePropertyOptionChange} name='r5' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 5:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={r6} onChange={handlePropertyOptionChange} name='r6' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 6:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={r7} onChange={handlePropertyOptionChange} name='r7' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break                                                                                                                                                
                }
            })
    
        }else {
            commercialOptions.forEach((item, i) => {
                switch(i) {
                    case 0:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={c1} onChange={handlePropertyOptionChange} name='c1' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 1:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={c2} onChange={handlePropertyOptionChange} name='c2' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 2:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={c3} onChange={handlePropertyOptionChange} name='c3' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 3:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={c4} onChange={handlePropertyOptionChange} name='c4' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 4:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={c5} onChange={handlePropertyOptionChange} name='c5' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 5:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={c6} onChange={handlePropertyOptionChange} name='c6' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break
                    case 6:
                        menu.push(
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={c7} onChange={handlePropertyOptionChange} name='c7' />
                                    }
                                    label={item}
                                />
                            </MenuItem>
                        )
                        break                                                                                                                                                
                }
            })
        }
    
        
        return (
            <Menu
                id="type-menu"
                aria-labelledby="type-menu-button"
                anchorEl={anchorType}
                open={openType}
                onClose={handleTypeClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
            >
                {menu}
            </Menu>
        )
    }

    return (
        <Card sx={{ width: '100%', backgroundColor: alpha('#005CA8', 0.35), borderRadius: '16px', mt: 4,  }}>
            <Tabs value={tabValue} onChange={handleTabChange} centered  
             sx={{
                ".MuiTab-textColorPrimary": {
                color: `white`,
                },
                ".Mui-selected": {
                    color: `white`,
                    },
                }}
            >
                <Tab textColorPrimary='white' label="Buy" />
                <Tab label="Rent" />
            </Tabs>
            <Paper
            component="form"
            sx={{ m: 4,  alignSelf:'center', alignContent: 'center', display: 'flex', alignItems: 'center', }}
            >
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    sx={{ ml: 1, flex: 1, p:1}}
                    placeholder="Enter locality or landmark in Singapore..."
                    inputProps={{ 'aria-label': 'search google maps' }}
                    {...register("query")} 
                />
                {/* <input type="text" id="accommodation_address" ref={inputRef} style={{
                        width: '100%',
                        padding: '8px',
                        marginBottom: '8px', 
                        flex: 1,
                        padding: '8xp',
                        margin: '8px', 
                        backgroundColor: '#fff',
                        borderWidth: '0px',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                    }}
                    onChange={(e) =>                          
                        onSelectPlace(e)
                    }
                    {...register("max_value")} 
                    /> */}
                <Button variant="contained" sx={{mr: 2}} onClick={searchProperties}>Search place</Button>
            </Paper> 
            <Stack direction="row" spacing={2} sx={{backgroundColor: '#fff', mt:1}}  justifyContent="center" alignItems="center">
                <Box>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={openType ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openType ? 'true' : undefined}
                        onClick={handleTypeNameClick}
                        endIcon={<KeyboardArrowDownIcon />}

                    >
                        Property Type
                    </Button>
                    <GetPropertyTypeMenu type={propertyType} anchorTypeName={anchorTypeName} openTypeName={openTypeName} handleTypeNameClose={handleTypeNameClose} />
                </Box>

                <Box>
                    <Button
                        id="type-menu-button"
                        aria-controls={openType ? 'type-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openType ? 'true' : undefined}
                        onClick={handleTypeClick}
                        endIcon={<KeyboardArrowDownIcon />}

                    >
                        Kind
                    </Button>
                   <GetPropertyKinds />
                </Box>
                <Box>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={openPrice ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openPrice ? 'true' : undefined}
                        onClick={handlePriceClick}
                        endIcon={<KeyboardArrowDownIcon />}

                    >
                        Price Range
                    </Button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorPrice}
                        open={openPrice}
                        onClose={() => handlePriceClose(0, 0)}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}

                    >
                        <MenuItem>
                            <Stack direction='row' spacing={2}>
                                <TextField
                                    inputProps={{
                                        style: {
                                        height: "5px",
                                        },
                                    }}
                                    sx={{width: '100px'}}
                                    id="price-min"
                                    label="Min"
                                    placeholder='Min'
                                    {...register("min_value")} 
                                />
                                <TextField
                                    inputProps={{
                                        style: {
                                        height: "5px",
                                        },
                                    }}
                                    sx={{width: '100px'}}
                                    id="price-max"
                                    label="Max"
                                    placeholder='Max'
                                    defaultValue="10000"
                                    {...register("max_value")} 
                                />
                            </Stack>
                        </MenuItem>
                        <MenuItem onClick={() => handlePriceClose(0, 0)}>Any Cost</MenuItem>
                        <MenuItem onClick={() => handlePriceClose(0, 10000)}>$0 - $10,000</MenuItem>
                        <MenuItem onClick={() => handlePriceClose(10000, 20000)}>$10,000 - $20,000</MenuItem>
                        <MenuItem onClick={() => handlePriceClose(20000, 50000)}>$20,000 - $50,000</MenuItem>
                        <MenuItem onClick={() => handlePriceClose(50000, 100000)}>$50,000 - $100,000</MenuItem>
                        <MenuItem onClick={() => handlePriceClose(100000, 500000)}>$100,000 - $500,000</MenuItem>
                        <MenuItem onClick={() => handlePriceClose(500000, 1000000)}>$500,000 - $1,00,000</MenuItem>
                    </Menu>
                </Box>

                <Box>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={openBeds ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openBeds ? 'true' : undefined}
                        onClick={handleBedsClick}
                        endIcon={<KeyboardArrowDownIcon />}

                    >
                        Bedrooms
                    </Button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorBeds}
                        open={openBeds}
                        onClose={handleBedsClose}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                    >
                        {/* <MenuItem>
                            <FormControlLabel
                                control={
                                <Checkbox checked={bedsAll} onChange={handleBedsOptionChange} name="bedsAll" />
                                }
                                label="All"
                            />
                        </MenuItem> */}
                        <MenuItem>
                            <FormControlLabel
                                control={
                                <Checkbox checked={beds1} onChange={handleBedsOptionChange} name="beds1" />
                                }
                                label="1 Bedroom"
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                control={
                                <Checkbox checked={beds2} onChange={handleBedsOptionChange} name="beds2" />
                                }
                                label="2 Bedrooms"
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                control={
                                <Checkbox checked={beds3} onChange={handleBedsOptionChange} name="beds3" />
                                }
                                label="3 Bedrooms"
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                control={
                                <Checkbox checked={beds4} onChange={handleBedsOptionChange} name="beds4" />
                                }
                                label="4 Bedrooms"
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                control={
                                <Checkbox checked={beds5p} onChange={handleBedsOptionChange} name="beds5p" />
                                }
                                label="5+ Bedrooms"
                            />
                        </MenuItem>
                    </Menu>
                    <input type="hidden" {...register(`type`)} />
                    <input type="hidden" {...register(`bedrooms`)} />

                </Box>

            </Stack>           
        </Card>       
    );
}